import { useMemo } from 'react';
import { keepPreviousData, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';
import { useRawIntel } from '@/client/features/intelligence/services/getRawIntel';
import { useFinishedIntel } from '@/client/features/intelligence/services/getFinishedIntel';
import { useAuth } from '@/hooks/useAuth';

import { openapiClient, safePromise } from '@/lib/openapi-fetch';
import {
  combineAggEntries,
  fillMissingDates,
  normalizeAggEntry,
  unifiedApiAggToDonutOption,
  unifiedApiAggToTimelineOption,
  unifiedApiToGradientOption,
} from '@/components/content/chart/utils/transformations';

import { unifiedApiKeys } from '@/services/keys';
import { createOverviewAttckObject } from '@/client/features/overviewattck/utils/createOverviewObject';
import { isNotNull, mergeUniqueArray } from '@/utils';
import { malwareKitsKeys } from './keys';
import { INTEL_ENDPOINTS } from '@/client/features/intelligence/services/keys';

import type { Schema,
  UnifiedApiAggResponse,
  UnifiedApiAggs,
  UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { FetchError } from '@/types/api';
import type { NonUndefined } from '@/types/general';

function malwareKitsResourceFetchFn(
  id: number,
) {
  return openapiClient.GET(
    '/catalog/malware_kits/{resource_id}',
    {
      params: {
        path: {
          resource_id: id,
        },
        query: {
          include: [
            'raw_intel.attcks',
          ],
        },
      },
      headers: { source: 'entity' },
    },
  );
}

async function malwareKitsEntityFetchFn(
  id: number,
) {
  const promises = await Promise.all([
    safePromise(malwareKitsResourceFetchFn(id)),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.finished,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `malware_kits.id:${id}`,
              'populate_catalog:true',
            ],
            agg: [
              // Relationships
              'threat_actor_intent',
              'threat_actor_capability',
              'personas',
              'threat_actors',
              'vulnerabilities',
              'malware_kits',
              'industries',
              'technologies',
              'locations',
              'iocs',

              // GFX
              'industries limit:5',
              'vulnerabilities limit:5',
              'technologies limit:5',
              'month(published_at) industries order:asc',
            ],
          },
        },
      },
    )),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.raw,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `malware_kits.id:${id}`,
            ],
            agg: [
              // Relationships
              'threat_actor_motivation',
              'threat_actor_intent',
              'threat_actor_capability',
              'personas',
              'threat_actors',
              'vulnerabilities',
              'malware_kits',
              'industries',
              'technologies',
              'locations',
              'iocs',

              // GFX
              'industries limit:5',
              'vulnerabilities limit:5',
              'technologies limit:5',
              'month(published_at) industries order:asc',
            ],
          },
        },
      },
    )),
  ]);

  if (promises.every((p) => isNotNull(p.error))) {
    throw new Error('All promises failed');
  }

  return promises;
}

type MalwareKitsContext = {
  threat_actor_motivation: ReturnType<typeof normalizeAggEntry>;
  threat_actor_intent: ReturnType<typeof normalizeAggEntry>;
  threat_actor_capability: ReturnType<typeof normalizeAggEntry>;
  personas: ReturnType<typeof normalizeAggEntry>;
  vulnerabilities: ReturnType<typeof normalizeAggEntry>;
  threat_actors: ReturnType<typeof normalizeAggEntry>;
  malware_kits: ReturnType<typeof normalizeAggEntry>;
  relevant_industries: ReturnType<typeof normalizeAggEntry>;
  relevant_technologies: ReturnType<typeof normalizeAggEntry>;
  relevant_locations: ReturnType<typeof normalizeAggEntry>;
  attcks_overview: ReturnType<typeof createOverviewAttckObject>;

  vulnerabilitiesTop5Option: ReturnType<typeof unifiedApiToGradientOption>;
  technologiesTop5Option: ReturnType<typeof unifiedApiToGradientOption>;
  industriesTimelineOption: ReturnType<typeof unifiedApiAggToTimelineOption>;
  iocs: Array<string>;
};

export type MalwareKitsResourceFetchResponse = NonUndefined<
Awaited<ReturnType<typeof malwareKitsResourceFetchFn>>['data']
>;
export type MalwareKitsEntity = MalwareKitsResourceFetchResponse & MalwareKitsContext;

export function malwareKitsEntityQuery(
  id: number,
  enabled?: boolean,
): UseQueryOptions<Awaited<ReturnType<typeof malwareKitsEntityFetchFn>>, FetchError, MalwareKitsEntity> {
  return {
    queryKey: unifiedApiKeys.ticket('/catalog/malware_kits/', id),
    queryFn: () => malwareKitsEntityFetchFn(id),
    select: (data) => {
      const [
        entity,
        finished,
        raw,
      ] = data;

      const finishedAggs = finished.data?.data as UnifiedApiAggResponse;
      const rawAggs = raw.data?.data as UnifiedApiAggResponse;

      const [
        threatActorIntent,
        threatActorCapability,
        personas,
        threatActors,
        vulnerabilities,
        malwareKits,
        industries,
        technologies,
        locations,
        iocs,

        // GFX
        industriesTop5,
        vulnerabilitiesTop5,
        technologiesTop5,
        industriesTimeline,
      ] = finishedAggs.aggs;

      const [
        threatActorMotivationRaw,
        threatActorIntentRaw,
        threatActorCapabilityRaw,
        personasRaw,
        threatActorsRaw,
        vulnerabilitiesRaw,
        malwareKitsRaw,
        industriesRaw,
        technologiesRaw,
        locationsRaw,
        iocsRaw,

        // GFX
        industriesTop5Raw,
        vulnerabilitiesTop5Raw,
        technologiesTop5Raw,
        industriesTimelineRaw,
      ] = rawAggs.aggs;

      const industriesTop5Set = new Set(
        combineAggEntries(industriesTop5, industriesTop5Raw).filter((d) => d.key.some(isNotNull)).map((i) => i.key[1]),
      );
      const industriesTimelineOption = unifiedApiAggToTimelineOption(
        fillMissingDates(
          combineAggEntries(industriesTimeline, industriesTimelineRaw)
            .filter((item) => industriesTop5Set.has(item.key[2])),
        ),
      );

      const contextData = {
        threat_actor_motivation: normalizeAggEntry(threatActorMotivationRaw ?? []),
        threat_actor_intent: normalizeAggEntry(combineAggEntries(threatActorIntent, threatActorIntentRaw)),
        threat_actor_capability: normalizeAggEntry(combineAggEntries(threatActorCapability, threatActorCapabilityRaw)),
        personas: normalizeAggEntry(combineAggEntries(personas, personasRaw)),
        vulnerabilities: normalizeAggEntry(combineAggEntries(vulnerabilities, vulnerabilitiesRaw)),
        threat_actors: normalizeAggEntry(combineAggEntries(threatActors, threatActorsRaw)),
        malware_kits: normalizeAggEntry(combineAggEntries(malwareKits, malwareKitsRaw)),
        relevant_industries: normalizeAggEntry(combineAggEntries(industries, industriesRaw)),
        relevant_technologies: normalizeAggEntry(combineAggEntries(technologies, technologiesRaw)),
        relevant_locations: normalizeAggEntry(combineAggEntries(locations, locationsRaw)),
        iocs: combineAggEntries(iocs, iocsRaw).map((ioc) => ioc.key[0]),

        // GFX
        vulnerabilitiesTop5Option: unifiedApiToGradientOption([...vulnerabilitiesTop5, ...vulnerabilitiesTop5Raw]),
        technologiesTop5Option: unifiedApiToGradientOption([...technologiesTop5, ...technologiesTop5Raw]),
        industriesTimelineOption,
      };

      const entityRawIntel = Array.isArray(entity.data?.data?.raw_intel) ? entity.data?.data?.raw_intel : [];

      return {
        ...(entity.data?.data as NonUndefined<MalwareKitsResourceFetchResponse>),
        ...contextData,
        attcks_overview: createOverviewAttckObject(entityRawIntel.flatMap((raw) => (
          Array.isArray(raw.attcks) ? raw.attcks : []
        ))),
      };
    },
    enabled,
  };
}

function useMalwareKits() {
  const service = useCreateUnifiedApiService('/catalog/malware_kits/', openapiClient);

  return {
    list: service.list,
  };
}

export function useMalwareKitsEntity(id: number, enabled?: boolean) {
  return useQuery(malwareKitsEntityQuery(id, enabled));
}

export function useMalwareKitsTable(
  {
    filter = [],
    ...params
  }: Partial<UnifiedApiParams>,
) {
  const auth = useAuth();

  return useMalwareKits().list({
    placeholderData: keepPreviousData,
    queryKey: malwareKitsKeys.table,
    select: (raw) => {
      const getFromRawIntel = (record: Schema<'MalwareKitResponse'>) => (
        Array.isArray(record.raw_intel) ? record.raw_intel.reduce((accu, curr) => {
          const {
            locations,
            industries,
            technologies,
            threat_actor_capability: threatActorCapability,
            threat_actor_intent: threatActorIntent,
            threat_actor_motivation: threatActorMotivation,
          } = curr;

          accu.relevantLocations = {
            ...accu.relevantLocations,
            ...locations as Record<string, string>,
          };

          accu.relevantIndustries = {
            ...accu.relevantIndustries,
            ...industries as Record<string, string>,
          };

          accu.relevantTechnologies = {
            ...accu.relevantTechnologies,
            ...technologies as Record<string, string>,
          };

          accu.threat_actor_capability = mergeUniqueArray(accu.threat_actor_capability, threatActorCapability);
          accu.threat_actor_intent = mergeUniqueArray(accu.threat_actor_intent, threatActorIntent);
          accu.threat_actor_motivation = mergeUniqueArray(accu.threat_actor_motivation, threatActorMotivation);

          return accu;
        }, {
          relevantLocations: {},
          relevantIndustries: {},
          relevantTechnologies: {},
          threat_actor_capability: [],
          threat_actor_intent: [],
          threat_actor_motivation: [],
        } as Record<
        'threat_actor_capability' | 'threat_actor_intent' | 'threat_actor_motivation',
        Array<string>
        > & Record<
        'relevantLocations' | 'relevantIndustries' | 'relevantTechnologies',
        Record<string, string>
        >) : {});

      const data = {
        ...raw.data,
        records: raw.data?.records.map((record) => ({
          ...record,
          ...getFromRawIntel(record),
        })),
      };

      return {
        ...data,
        typeGfx: unifiedApiAggToDonutOption(data.aggs?.[0] as UnifiedApiAggs),
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        filter: [
          'raw_intel:*',
          ...filter,
        ],
        include: [
          'raw_intel',
        ],
        agg: [
          'type',
        ],
        ...params,
      },
    },
    headers: { source: 'table' },
  });
}

export function useMalwareKitsGFX(
  filter: UnifiedApiParams['filter'] = [],
) {
  const auth = useAuth();

  const finishedIntel = useFinishedIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => malwareKitsKeys.gfx(INTEL_ENDPOINTS.finished, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        locations,
        malwareKits,
        malwareKitsTimeline,
        attckMitreName,
      ] = data.aggs;

      return {
        locations,
        malwareKits,
        malwareKitsTimeline,
        attckMitreName,
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        filter: [
          'malware_kits:{name:*}',
          'populate_catalog:true',
          ...(filter.filter((val) => !val.includes('motivation'))),
        ],
        agg: [
          'locations',
          'malware_kits limit:5',
          'month(published_at) malware_kits order:asc',
          'attcks.mitre_kill_chain',
        ],
      },
    },
  });

  const rawIntel = useRawIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => malwareKitsKeys.gfx(INTEL_ENDPOINTS.raw, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        locations,
        malwareKits,
        malwareKitsTimeline,
        threatActorMotivation,
        attckMitreName,
      ] = data.aggs;

      return {
        locations,
        malwareKits,
        malwareKitsTimeline,
        threatActorMotivation,
        attckMitreName,
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        length: 0,
        filter: [
          'malware_kits:{name:*}',
          ...filter,
        ],
        agg: [
          'locations',
          'malware_kits limit:5',
          'month(published_at) malware_kits order:asc',
          'threat_actor_motivation',
          'attcks.mitre_kill_chain',
        ],
      },
    },
  });

  const data = useMemo(() => {
    if (!finishedIntel.data && !rawIntel.data) return undefined;

    const {
      locations,
      malwareKits,
      malwareKitsTimeline,
      attckMitreName,
    } = finishedIntel.data || {};

    const {
      locations: locationsRaw,
      malwareKits: malwareKitsRaw,
      malwareKitsTimeline: malwareKitsTimelineRaw,
      attckMitreName: attckMitreNameRaw,
      threatActorMotivation,
    } = rawIntel.data || {};

    const top5MalwareKits = unifiedApiToGradientOption(combineAggEntries(malwareKitsRaw, malwareKits).slice(0, 5));
    const top5Set = new Set(top5MalwareKits.dimensions);
    const malwareKitsTimelineOption = unifiedApiAggToTimelineOption(
      fillMissingDates(
        combineAggEntries(malwareKitsTimelineRaw, malwareKitsTimeline).filter((item) => top5Set.has(item.key[2])),
      ),
    );

    return {
      top5MalwareKits,
      malwareKitsTimelineOption,
      locationsMap: unifiedApiAggToDonutOption(combineAggEntries(locationsRaw, locations)),
      motivationDonut: unifiedApiAggToDonutOption(threatActorMotivation),
      mitreDonut: unifiedApiAggToDonutOption(combineAggEntries(attckMitreNameRaw, attckMitreName)),
    };
  }, [finishedIntel.data, rawIntel.data]);

  return {
    isPending: finishedIntel.isPending || rawIntel.isPending,
    isError: finishedIntel.isError || rawIntel.isError,
    isFetching: finishedIntel.isFetching || rawIntel.isFetching,
    error: finishedIntel.error && rawIntel.error,
    data,
  };
}
