import FullPageContent from '@/components/content/box/FullPageContent';
import SupportForm from '../SupportForm';

import { getValues } from '@/utils';
import { SUPPORT_SUBJECTS, SUPPORT_TYPES } from '../types';
import { useSearchParams } from 'react-router-dom';

const Support = () => {
  const [searchParams] = useSearchParams();
  const subject = searchParams.get('subject');
  const validSubject = getValues(SUPPORT_SUBJECTS).find((sub) => sub === subject);

  return (
    <FullPageContent>
      <SupportForm
        key="contact-support"
        name="support-form"
        subjects={getValues(SUPPORT_SUBJECTS)}
        triggerOtherOn={SUPPORT_SUBJECTS.OTHER}
        prefillData={{
          subject: validSubject,
          to: SUPPORT_TYPES.SUPPORT,
        }}
        attachment
      />
    </FullPageContent>
  );
};

export default Support;
