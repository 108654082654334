import { useState } from 'react';
import { useTimeout } from '@mantine/hooks';
import { useQueries } from '@tanstack/react-query';

import { openapiClient } from '@/lib/openapi-fetch';
import { notificationsKeys } from './keys';
import { INCIDENTS_ENDPOINTS } from '../../incidentmanagement/services/keys';
import { INTEL_ENDPOINTS } from '../../intelligence/services/keys';
import { MercuryTrackersIds } from '@/constants/mercuryPages';
import { OIncidentsStatus } from '../../incidentmanagement/types';

export function useGetNotifications() {
  const [enabled, setEnabled] = useState(false);
  const forceHide = localStorage.getItem('isPlaywright');

  useTimeout(() => setEnabled(!forceHide), 1000, {
    autoInvoke: true,
  });

  const unreadFilters = (
    filter: Array<string> = [],
  ) => ({
    params: {
      query: {
        length: 0,
        filter: [
          ...filter,
          'NOT user_state:* OR user_state:{read:false}',
        ],
      },
    },
    priority: 'low' as RequestPriority,
  });

  const newFilters = (
    filter: Array<string> = [],
  ) => ({
    params: {
      query: {
        length: 0,
        filter: [
          ...filter,
          `NOT client_state:* OR client_state:{status:${OIncidentsStatus.New}}`,
        ],
      },
    },
    priority: 'low' as RequestPriority,
  });

  const unreadQueriesCOnfig = [
    {
      queryKey: notificationsKeys.notificationUnread(MercuryTrackersIds.finished),
      queryFn: () => openapiClient.GET(INTEL_ENDPOINTS.finished, unreadFilters(['product_type:*'])),
      enabled,
      id: MercuryTrackersIds.finished,
    },
    {
      queryKey: notificationsKeys.notificationUnread(MercuryTrackersIds.raw),
      queryFn: () => openapiClient.GET(INTEL_ENDPOINTS.raw, unreadFilters(['category:*'])),
      enabled,
      id: MercuryTrackersIds.raw,
    },
    {
      queryKey: notificationsKeys.notificationUnread(MercuryTrackersIds.vulnerabilities),
      queryFn: () => (
        openapiClient.GET(
          INCIDENTS_ENDPOINTS.incidents,
          unreadFilters(['category:* AND subcategory:New Vulnerability']),
        )
      ),
      enabled,
      id: MercuryTrackersIds.vulnerabilities,
    },
  ];

  const unreadQueries = useQueries({
    queries: unreadQueriesCOnfig.map(({ id: _, ...query }) => ({
      ...query,
    })),
    combine: (data) => {
      const total = data.reduce((acc, query) => acc + (query.data?.data?.count || 0), 0);

      return {
        total,
        values: data.map((query, index) => ({
          notification: query.data?.data?.count || 0,
          id: unreadQueriesCOnfig[index].id,
        })),
        isError: data.some((result) => result.isError),
        isPending: data.some((result) => result.isPending),
      };
    },
  });

  const newQueriesConfig = [
    {
      queryKey: notificationsKeys.notificationNew(MercuryTrackersIds.alerts),
      queryFn: () => (
        openapiClient.GET(
          INCIDENTS_ENDPOINTS.incidents,
          newFilters([
            'category:* AND NOT subcategory:"New Vulnerability"',
            'NOT rfis:*',
            'NOT takedowns:*',
          ]),
        )
      ),
      enabled,
      id: MercuryTrackersIds.alerts,
    },
    {
      queryKey: notificationsKeys.notificationNew(MercuryTrackersIds.takedowns),
      queryFn: () => (
        openapiClient.GET(
          INCIDENTS_ENDPOINTS.takedowns,
          newFilters(),
        )
      ),
      enabled,
      id: MercuryTrackersIds.takedowns,
    },
    {
      queryKey: notificationsKeys.notificationNew(MercuryTrackersIds.vulnerabilities),
      queryFn: () => (
        openapiClient.GET(
          INCIDENTS_ENDPOINTS.incidents,
          newFilters(['category:* AND subcategory:New Vulnerability']),
        )
      ),
      enabled,
      id: MercuryTrackersIds.vulnerabilities,
    },
  ];

  const newQueries = useQueries({
    queries: newQueriesConfig.map(({ id: _, ...query }) => ({
      ...query,
    })),
    combine: (data) => {
      const total = data.reduce((acc, query) => acc + (query.data?.data?.count || 0), 0);

      return {
        total,
        values: data.map((query, index) => ({
          notification: query.data?.data?.count || 0,
          id: newQueriesConfig[index].id,
        })),
        isError: data.some((result) => result.isError),
        isPending: data.some((result) => result.isPending),
      };
    },
  });

  const isPending = unreadQueries.isPending || newQueries.isPending;
  const isError = unreadQueries.isError || newQueries.isError;
  return {
    isPending,
    isError,
    unread: unreadQueries,
    new: newQueries,
  };
}
