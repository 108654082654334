import { getValues } from '@/utils';
import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

const Redirect = ({
  to,
  search,
}: {
  to: string;
  search?: string | ((val?: string) => string);
}) => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let basePath = generatePath(to, params);
    let searchQuery = '';

    if (search) {
      searchQuery = typeof search === 'string' ? `?${search}${getValues(params)}` : search(getValues(params)[0]);
    }

    basePath = `${basePath}${searchQuery}`;

    navigate(`${basePath}`, { replace: true });
  });

  return null;
};

export default Redirect;
