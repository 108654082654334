import DeferredDrawerLink from '@/client/features/drawerlink/DeferredDrawerLink';
import BlockedLink from '@/client/features/blockedlink/BlockedLink';

import { threatActorsEntityQuery } from '../services/threatActors';
import { threatActorDrawer } from '../configs/threatactors.drawer';
import { CATALOGS } from '@/constants/permissions';

interface ThreatActorsDrawerProps {
  id: number;
  name: string;
  amount?: number;
}

const ThreatActorsDrawer = ({
  id,
  name,
  amount,
}: ThreatActorsDrawerProps) => {
  const url = `/catalogs/threatactors/entity/${id}`;
  const displayName = amount ? `${name} (${amount})` : name;

  return (
    <BlockedLink id={id} name={displayName} modules={CATALOGS.threatactors.modules}>
      <DeferredDrawerLink
        id={id}
        value={displayName}
        content={threatActorDrawer}
        query={threatActorsEntityQuery(id)}
        link={url}
      />
    </BlockedLink>
  );
};

export default ThreatActorsDrawer;
