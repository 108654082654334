import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Group, Text } from '@mantine/core';

import { mercuryTrackers, TMercuryTrackersIds } from '@/constants/mercuryPages';

import classes from '../Notifications.module.css';

interface NotificationProps {
  items: Array<{ id: TMercuryTrackersIds, notification: number }>;
  emptyMsg: string;
  footer?: ReactNode;
}

const Notifications = ({
  items,
  emptyMsg,
  footer: Footer,
}: NotificationProps) => (
  <>
    <ul>
      {items.map(({ id, notification }) => {
        const tracker = mercuryTrackers[id];

        return (
          <li className={classes.menuItem} key={id}>
            <Link to={`/${tracker.parent}`}>
              <Group p={10}>
                <span><b>{tracker.title}</b></span>

                <Text
                  size="sm"
                  classNames={{ root: classes.menuItemtext }}
                  span
                >
                  {notification}
                </Text>
              </Group>
            </Link>
          </li>
        );
      })}
    </ul>

    {items.length === 0 && (<Text size="lg" c="var(--quo-grey-50)" ta="center">No {emptyMsg} tickets</Text>)}

    {Footer}
  </>
);

export default Notifications;
