import { postMutation } from '@/lib/react-query/mutate';

export const METIS_TASK_NAME = {
  COMMENT: 'comments',
  ACTIVITY: 'activities',
  ISSUE: 'issues',
  ENTITY: 'entities',
} as const;

type TaskName = typeof METIS_TASK_NAME[keyof typeof METIS_TASK_NAME];

export const useMetisTriggerReplication = (name: TaskName) => postMutation({ endpoint: `metis/tasks/${name}` });
