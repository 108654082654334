import DeferredDrawerLink from '@/client/features/drawerlink/DeferredDrawerLink';
import BlockedLink from '@/client/features/blockedlink/BlockedLink';

import { sourcesEntityQuery } from '../services/sources';
import { sourcesDrawer } from '../configs/sources.drawer';
import { CATALOGS } from '@/constants/permissions';

interface SourcesDrawerProps {
  id: number;
  name: string;
  amount?: number;
}

const SourcesDrawer = ({
  id,
  name,
  amount,
}: SourcesDrawerProps) => {
  const url = `/catalogs/sources/entity/${id}`;
  const displayName = amount ? `${name} (${amount})` : name;

  return (
    <BlockedLink id={id} name={displayName} modules={CATALOGS.sources.modules}>
      <DeferredDrawerLink
        id={id}
        value={displayName}
        content={sourcesDrawer}
        query={sourcesEntityQuery(id)}
        link={url}
      />
    </BlockedLink>
  );
};

export default SourcesDrawer;
