import { Group, Text, Tooltip } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';

import BaseResult from '@/client/features/search/components/BaseResult';
import List from '@/components/content/list/List';
import IssueIDLinkv2 from '@/client/features/issueIdLink/IssueIdLink';
import VulnerabilitiesDrawer from './VulnerabilitiesDrawer';

import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';
import { formatIsoDate } from '@/utils/date';
import { aggregateFromMetisRelationship } from '../../../utils/aggregateFromMetisRelationship';
import { getContextData } from '../../../utils/context';

import type { Includes } from '@/types/mercury-data-types/metis';
import type { IncidentListRecord } from '@/client/features/incidentmanagement/types';
import type { VulnerabilitiesResourceFetchResponse } from '../services/vulnerabilities';
import type { RawIntelListRecord } from '@/client/features/intelligence/types';

const VulnerabilitiesSearchResult = ({
  data,
}: {
  data: Includes<
  VulnerabilitiesResourceFetchResponse, {
    raw_intel: Array<RawIntelListRecord>;
    incidents: Array<IncidentListRecord>;
  }
  >
}) => {
  const ta = aggregateFromMetisRelationship(data.raw_intel, 'threat_actors');
  const vulnerabilities = aggregateFromMetisRelationship(data.raw_intel, 'vulnerabilities');
  const malwareKits = aggregateFromMetisRelationship(data.raw_intel, 'malware_kits');
  const context = getContextData({ vulnerabilities, malware_kits: malwareKits, threat_actors: ta });

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'critical':
        return 'var(--quo-pink)';
      case 'high':
        return 'var(--quo-power)';
      case 'medium':
        return 'var(--quo-yellow)';
      case 'low':
        return 'var(--quo-green)';
      default:
        return 'var(--quo-noir-50)';
    }
  };

  return (
    <BaseResult
      title={{ id: data.id, name: data.name ?? 'Unknown' }}
      content={data.description}
      tracker={mercuryTrackers[MercuryTrackersIds.vulnerabilitiesCat]}
      customLink={<VulnerabilitiesDrawer id={data.id} name={data.name ?? 'Unknown'} />}
      footer={[
        <Text size="sm">
          <Text fw="bold" span>Last Updated:</Text> {formatIsoDate(data.updated_at)}
        </Text>,
        data.incidents.length > 0
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Related Incidents:</Text>
            <List
              gap={10}
              direction="row"
              items={data.incidents}
              renderItem={({ id }: any) => (
                <IssueIDLinkv2
                  value={id}
                  name={String(id)}
                  tracker={mercuryTrackers[MercuryTrackersIds.alerts]}
                  useParent
                  usePage
                />
              )}
            />
          </Group>
        ),
        <Text size="sm">
          <Text fw="bold" span>Priority: </Text>
          <Text fw="bold" c={getPriorityColor(data.priority)} span>{upperFirst(data.priority)}</Text>
        </Text>,
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip label={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
      ]}
    />
  );
};

export default VulnerabilitiesSearchResult;
