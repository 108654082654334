import { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { Signal, SignalsFetchResponse, SignalsSignalFetchResponse } from '@/types/mercury-data-types/signals';

import { findInObject } from '@/utils/findInObject';
import { unifiedApiKeys } from '../keys';
import { getEntries } from '@/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { openapiClient, openApiFetch } from '@/lib/openapi-fetch';

const BASE_PATHS = {
  COMMON: 'common',
  SYSTEM: 'system',
  STRUCTURED: 'data.structured',
  CATALOG: 'catalog',
  METADATA: 'metadata',
  TRANSLATIONS: 'translations',
  ATTRIBUTES: 'attributes',
} as const;

export const SIGNALS_PATHS = {
  ID: [
    'signal_id',
    `${BASE_PATHS.METADATA}.id`,
  ],
  TITLE: [
    `${BASE_PATHS.TRANSLATIONS}.en.${BASE_PATHS.STRUCTURED}.title`,
    `${BASE_PATHS.COMMON}.title`,
    `${BASE_PATHS.STRUCTURED}.title`,
  ],
  SIGNAL_SOURCE: [
    `${BASE_PATHS.SYSTEM}.source`,
  ],
  SUMMARY_ONLY: [
    `${BASE_PATHS.COMMON}.quo_summary`,
  ],
  FORUM_AUTHOR: [
    `${BASE_PATHS.STRUCTURED}.author.name`,
  ],
  AUTHOR_JOB_TITLE: [
    `${BASE_PATHS.STRUCTURED}.author.jobtitle`,
  ],
  TELEGRAM_AUTHOR: [
    `${BASE_PATHS.STRUCTURED}.Sender.User.Username`,
  ],
  TRANSLATED_TEXT: [
    `${BASE_PATHS.STRUCTURED}.content.text`,
  ],
  THREAD_TITLE: [
    `${BASE_PATHS.TRANSLATIONS}.en.${BASE_PATHS.STRUCTURED}.threat.title`,
    `${BASE_PATHS.STRUCTURED}.thread.title`,
  ],
  THREAD_PATH: [
    `${BASE_PATHS.STRUCTURED}.thread.path`,
  ],
  CHANNEL_TITLE: [
    `${BASE_PATHS.STRUCTURED}.Channel.title`,
  ],
  TEXT: [
    `${BASE_PATHS.COMMON}.quo_summary`,
    `${BASE_PATHS.TRANSLATIONS}.en.${BASE_PATHS.STRUCTURED}.summary`,
    `${BASE_PATHS.TRANSLATIONS}.en.${BASE_PATHS.STRUCTURED}.content.summary`,
    `${BASE_PATHS.TRANSLATIONS}.en.${BASE_PATHS.STRUCTURED}.content.text`,
    `${BASE_PATHS.TRANSLATIONS}.en.${BASE_PATHS.STRUCTURED}.Text`,
    `${BASE_PATHS.COMMON}.summary`,
    `${BASE_PATHS.STRUCTURED}.content.summary`,
    `${BASE_PATHS.STRUCTURED}.content.text`,
    `${BASE_PATHS.STRUCTURED}.Text`,
  ],
  TIMESTAMP: [
    `${BASE_PATHS.COMMON}.timestamp`,
    `${BASE_PATHS.STRUCTURED}.timestamp`,
    '@timestamp',
  ],
  PUBLISHED_AT: [`${BASE_PATHS.METADATA}.published_at`],
  UPDATED_AT: [`${BASE_PATHS.METADATA}.updated_at`],
  INGESTED_AT: [`${BASE_PATHS.METADATA}.ingested_at`],
  LINK: [`${BASE_PATHS.METADATA}.link`],
  CATEGORY: [`${BASE_PATHS.CATALOG}.category`],
  THREAT_ACTOR: [`${BASE_PATHS.CATALOG}.threatactor`],
  MALWARE_KIT: [`${BASE_PATHS.CATALOG}.malwarekit`],
  VULNERABILITY: [`${BASE_PATHS.CATALOG}.vulnerability`],
  SOURCE_ID: [`${BASE_PATHS.CATALOG}.source_id`],
  SOURCE_NAME: [
    `${BASE_PATHS.CATALOG}.source`,
    `${BASE_PATHS.METADATA}.collector.name`,
  ],
  TYPE: [`${BASE_PATHS.CATALOG}.type`],
  INDUSTRY_ID: [`${BASE_PATHS.CATALOG}.industrysector_id`],
  INDUSTRY_NAME: [`${BASE_PATHS.CATALOG}.industrysector`],
  TECHNOLOGY_ID: [`${BASE_PATHS.CATALOG}.technology_id`],
  TECHNOLOGY: [`${BASE_PATHS.CATALOG}.technology`],
  LOCATION_ID: [`${BASE_PATHS.CATALOG}.country_id`],
  LOCATION_NAME: [`${BASE_PATHS.CATALOG}.country`],
  DOMAIN: [`${BASE_PATHS.ATTRIBUTES}.domain`],
  EMAIL: [`${BASE_PATHS.ATTRIBUTES}.email`],
  HOSTNAME: [`${BASE_PATHS.ATTRIBUTES}.hostname`],
  IP: [`${BASE_PATHS.ATTRIBUTES}.ip`],
  URL: [`${BASE_PATHS.ATTRIBUTES}.url`],
  PASSWORD_ATTRIBUTE: [`${BASE_PATHS.ATTRIBUTES}.password`],
  PASSWORD: [
    `${BASE_PATHS.STRUCTURED}.credential.password`,
  ],
};

const moveToObject = (
  data: Record<string, never>,
  { id, name }: { id: Array<string>; name: Array<string> },
): Record<string, any> => {
  const ids = findInObject(data, id, []) as Array<any>;
  const names = findInObject(data, name, []) as Array<any>;

  // return {key: value}f format:
  return ids.reduce((accu, id, index) => {
    accu[id] = names[index];

    return accu;
  }, {});
};

export type NormalizedSignal = ReturnType<typeof normalizeSignal>;

export const normalizeSignal = (signal: Signal) => ({
  id: signal.signal_id as any || findInObject(signal, SIGNALS_PATHS.ID) as any,
  title: findInObject(signal, SIGNALS_PATHS.TITLE),
  text: findInObject(signal, SIGNALS_PATHS.TEXT),
  summary: findInObject(signal, SIGNALS_PATHS.SUMMARY_ONLY),
  translated_forum: findInObject(signal, SIGNALS_PATHS.TRANSLATED_TEXT),
  timestamp: findInObject(signal, SIGNALS_PATHS.TIMESTAMP),
  published_at: findInObject(signal, SIGNALS_PATHS.PUBLISHED_AT),
  updated_at: findInObject(signal, SIGNALS_PATHS.UPDATED_AT),
  ingested_at: findInObject(signal, SIGNALS_PATHS.INGESTED_AT),
  link: findInObject(signal, SIGNALS_PATHS.LINK),
  source: findInObject(signal, SIGNALS_PATHS.SIGNAL_SOURCE),
  credentials: findInObject(signal, SIGNALS_PATHS.PASSWORD),
  attributes: {
    domain: findInObject(signal, SIGNALS_PATHS.DOMAIN),
    email: findInObject(signal, SIGNALS_PATHS.EMAIL),
    hostname: findInObject(signal, SIGNALS_PATHS.HOSTNAME),
    ip: findInObject(signal, SIGNALS_PATHS.IP),
    url: findInObject(signal, SIGNALS_PATHS.URL),
    password: findInObject(signal, SIGNALS_PATHS.PASSWORD_ATTRIBUTE),
  },
  author: {
    jobtitle: findInObject(signal, SIGNALS_PATHS.AUTHOR_JOB_TITLE),
    forum: findInObject(signal, SIGNALS_PATHS.FORUM_AUTHOR),
    telegram: findInObject(signal, SIGNALS_PATHS.TELEGRAM_AUTHOR),
  },
  thread: {
    title: findInObject(signal, SIGNALS_PATHS.THREAD_TITLE),
    path: findInObject(signal, SIGNALS_PATHS.THREAD_PATH),
  },
  channel: {
    title: findInObject(signal, SIGNALS_PATHS.CHANNEL_TITLE),
  },
  catalog: {
    category: findInObject(signal, SIGNALS_PATHS.CATEGORY),
    vulnerability: findInObject(signal, SIGNALS_PATHS.VULNERABILITY),
    threatactor: findInObject(signal, SIGNALS_PATHS.THREAT_ACTOR),
    malwarekit: findInObject(signal, SIGNALS_PATHS.MALWARE_KIT),
    source: findInObject(signal, SIGNALS_PATHS.SOURCE_NAME),
    source_id: findInObject(signal, SIGNALS_PATHS.SOURCE_ID),
    technologies: moveToObject(signal, {
      id: SIGNALS_PATHS.TECHNOLOGY_ID,
      name: SIGNALS_PATHS.TECHNOLOGY,
    }),
    locations: moveToObject(signal, {
      id: SIGNALS_PATHS.LOCATION_ID,
      name: SIGNALS_PATHS.LOCATION_NAME,
    }),
    industry_sectors: moveToObject(signal, {
      id: SIGNALS_PATHS.INDUSTRY_ID,
      name: SIGNALS_PATHS.INDUSTRY_NAME,
    }),
  },
});

export const signalQuery = (id: string) => ({
  queryKey: unifiedApiKeys.ticket('/signals', id),
  queryFn: () => openApiFetch(
    '/signals/{signal_id}',
    {
      params: {
        path: { signal_id: id },
      },
      headers: { source: 'entity' },
    },
  ),
  select: (raw: any) => {
    const data = raw as SignalsSignalFetchResponse;

    return normalizeSignal(data.data as any);
  },
});

export const useSignalEntity = (id: string) => useQuery(signalQuery(id));

export const useSignals = (
  params: Partial<UnifiedApiParams>,
) => {
  const list = <TResult = SignalsFetchResponse>(
    select?: (data: SignalsFetchResponse) => TResult,
  ) => useQuery({
      queryKey: unifiedApiKeys.records('/signals', params),
      queryFn: async () => openapiClient.GET('/signals', { params: { query: params } }),
      select,
      placeholderData: keepPreviousData,
    });

  const summary = () => useQuery({
    queryKey: unifiedApiKeys.summary('/signals/summary', params),
    queryFn: async () => openapiClient.GET('/signals/summary', { params: { query: params } }),
    select: (data) => ({
      ...data,
      area: data.data ? getEntries(data.data?.signal_timeline).map(([key, value]) => ([+new Date(key), value])) : [],
    }),
    placeholderData: keepPreviousData,
  });

  return {
    summary,
    list,
  };
};
