import type { TableState } from '@tanstack/react-table';

// Components
import RouterLink from '@/components/content/link/RouterLink';
import Relevant, { type RelevantProps } from './Relevant';

// Types
import { Tracker } from '@/constants/mercuryPages';

interface TicketIDLinkProps {
  value: number;
  name?: string;
  tracker?: Tracker;
  useParent?: boolean;
  usePage?: boolean;
  tableState?: TableState;
  relevantData?: RelevantProps;
  onClick?: VoidFunction;
}

const IssueIDLinkv2 = ({
  value,
  name,
  tracker,
  useParent,
  usePage,
  tableState,
  relevantData,
  onClick,
}: TicketIDLinkProps) => {
  const state = tableState ? {
    start: tableState.pagination.pageIndex * tableState.pagination.pageSize,
    length: tableState.pagination.pageSize,
  } : null;

  const { parent, page, type } = tracker ?? {};

  // eslint-disable-next-line max-len
  const link = `${useParent && parent ? `/${parent}/` : ''}${(usePage || useParent) && page ? `${page}/` : ''}${type}/${value}`;

  return (
    <Relevant
      industries={relevantData?.industries ?? []}
      locations={relevantData?.locations ?? []}
      technologies={relevantData?.technologies ?? []}
    >
      <RouterLink
        to={link}
        state={{ tableState: state }}
        onClick={onClick}
      >
        {name || value}
      </RouterLink>
    </Relevant>
  );
};
export default IssueIDLinkv2;
