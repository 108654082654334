import { getEntries } from '@/utils';

import type { Locations } from '@/types/catalogs';

export function getRegions(locations: Array<Locations>) {
  return locations.reduce((accu, curr) => {
    if (curr.type === 'region') {
      accu[curr.id] = curr.name;
    } else {
      const parent = getEntries(curr.parents);

      if (parent.length) {
        parent.forEach(([key, value]) => { accu[+key] = value; });
      }
    }
    return accu;
  }, {} as Record<number, string>);
}
