import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';

import { openapiClient, safePromise } from '@/lib/openapi-fetch';
import { sourcesKeys } from './keys';
import { unifiedApiKeys } from '@/services/keys';

import { INTEL_ENDPOINTS } from '@/client/features/intelligence/services/keys';

import { UnifiedApiAggResponse, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import { isNotNull } from '@/utils';
import { combineAggEntries, normalizeAggEntry } from '@/components/content/chart/utils/transformations';
import { FetchError } from '@/types/api';
import { NonUndefined } from 'react-hook-form';

export function useSources() {
  const service = useCreateUnifiedApiService('/catalog/sources/', openapiClient);

  return {
    list: service.list,
  };
}

function sourcesResourceFetchFn(
  id: number,
) {
  return openapiClient.GET(
    '/catalog/sources/{resource_id}',
    {
      params: {
        path: { resource_id: id },
      },
      headers: { source: 'entity' },
    },
  );
}

async function sourcesEntityFetchFn(id: number) {
  const promises = await Promise.all([
    safePromise(sourcesResourceFetchFn(id)),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.finished,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `sources.id:${id}`,
              'populate_catalog:true',
            ],
            agg: [
              // Relationships
              'industries',
              'technologies',
              'locations',
            ],
          },
        },
      },
    )),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.raw,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `sources.id:${id}`,
            ],
            agg: [
              // Relationships
              'industries',
              'technologies',
              'locations',
            ],
          },
        },
      },
    )),
  ]);

  if (promises[0].error) {
    throw new Error(promises[0].error);
  }

  if (promises.every((p) => isNotNull(p.error))) {
    throw new Error('Failed to fetch sources entity');
  }

  return promises;
}

type SourcesContext = {
  relevant_industries: ReturnType<typeof normalizeAggEntry>;
  relevant_technologies: ReturnType<typeof normalizeAggEntry>;
  relevant_locations: ReturnType<typeof normalizeAggEntry>;
};

export type SourcesResourceFetchResponse = NonUndefined<
Awaited<ReturnType<typeof sourcesResourceFetchFn>>['data']
>;

export type SourcesEntity = SourcesResourceFetchResponse & SourcesContext;

export function sourcesEntityQuery(
  id: number,
): UseQueryOptions<Awaited<ReturnType<typeof sourcesEntityFetchFn>>, FetchError, SourcesEntity> {
  return {
    queryKey: unifiedApiKeys.ticket('/catalog/sources/', id),
    queryFn: () => sourcesEntityFetchFn(id),
    select: (data) => {
      const [
        entity,
        finished,
        raw,
      ] = data;

      const finishedAggs = finished.data?.data as UnifiedApiAggResponse;
      const rawAggs = raw.data?.data as UnifiedApiAggResponse;

      const [
        industries,
        technologies,
        locations,
      ] = finishedAggs.aggs;

      const [
        industriesRaw,
        technologiesRaw,
        locationsRaw,
      ] = rawAggs.aggs;

      const contextData = {
        relevant_industries: normalizeAggEntry(combineAggEntries(industries, industriesRaw)),
        relevant_technologies: normalizeAggEntry(combineAggEntries(technologies, technologiesRaw)),
        relevant_locations: normalizeAggEntry(combineAggEntries(locations, locationsRaw)),
      };

      return {
        ...(entity.data?.data as NonUndefined<SourcesResourceFetchResponse>),
        ...contextData,
      };
    },
  };
}

export function useSourcesEntity(id: number) {
  return useQuery(sourcesEntityQuery(id));
}

export function useSourcesTable({
  filter,
  ...params
}: Partial<UnifiedApiParams>) {
  return useSources().list({
    queryKey: sourcesKeys.table,
  }, {
    params: {
      query: {
        filter: [
          'raw_intel:*',
          ...(filter || []),
        ],
        ...params,
      },
    },
    headers: { source: 'table' },
  });
}
