import { cloneElement, PropsWithChildren, ReactNode, useCallback } from 'react';
import { ActionIcon, isElement, Tooltip } from '@mantine/core';

import { useToast } from '@/hooks/useToast';
import { postMutation } from '@/lib/react-query/mutate';

function useClientStateMutation(
  resource: string,
) {
  const { createToast } = useToast();

  return postMutation({
    endpoint: `v0${resource}bulk`,
    method: 'PATCH',
    mutationOptions: {
      onError: (error) => {
        createToast(error.message, 'error');
      },
    },
  });
}

type ClientStateTriggerProps = IconProps | ChildrenProps;

interface BaseProps {
  resource: string;
  body: Record<string, string>;
  // [[id1, [client_id1, client_id2, ...]], [id2, [client_id1, client_id2, ...]]]
  updateIds: Array<[number, Array<string>]>;
  onSuccess?: VoidFunction;
  disabled?: boolean;
}

interface IconProps extends BaseProps {
  icon: ReactNode;
  label: string;
  color?: string;
  loading?: boolean;
  size?: number;
  children?: never; // This ensures children can't be used with icon
}

interface ChildrenProps extends BaseProps {
  icon?: never; // This ensures icon can't be used with children
  label?: never;
  color?: never;
  size?: never;
  loading?: never;
  children: ReactNode;
}

const ClientStateTrigger = ({
  resource,
  body,
  updateIds,
  onSuccess,
  children,
  icon,
  label,
  color,
  size = 20,
  loading,
  disabled,
}: PropsWithChildren<ClientStateTriggerProps>) => {
  const mutation = useClientStateMutation(resource);

  if (children && !isElement(children)) {
    throw new Error(
      // eslint-disable-next-line max-len
      'ClientStateTrigger component children should be an element or a component that accepts ref. Fragments, strings, numbers and other primitive values are not supported',
    );
  }

  const onClick = useCallback(() => {
    mutation.mutate(
      updateIds.map(([id, client_ids]) => ({
        id,
        body: {
          client_state: client_ids.map((client_id) => ({
            client_id,
            ...body,
          })),
        },
      })),
      {
        onSuccess,
      },
    );
  }, [body, updateIds]);

  return icon ? (
    <Tooltip label={label}>
      <ActionIcon
        onClick={onClick}
        size={size}
        variant="subtle"
        aria-label={label}
        loaderProps={{ size: size - 4 }}
        loading={loading || mutation.isPending}
        color={color}
        disabled={disabled}
      >
        {icon}
      </ActionIcon>
    </Tooltip>
  ) : (
    cloneElement(children as any, {
      onClick,
      loading: loading || mutation.isPending,
      disabled: disabled || mutation.isPending,
    })
  );
};

export default ClientStateTrigger;
