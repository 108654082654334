import { Route } from 'react-router-dom';

import { SentryRoutes } from '@/sentry';

// Components
import AppMain from '../App';
import Layout from '@/components/layout/Layout';
import NotificationsMenu from './features/notifications/Menu';
import CoinMenuItem from '@/components/layout/nav/topbar/CoinMenuItem';
import Login from '@/features/auth/pages/Login';
import ActivateTFA from '@/features/auth/pages/ActivateTFA';
import ForgotPassword from '@/features/auth/pages/ForgotPassword';
import ResetPassword from '@/features/auth/pages/ResetPassword';
import Auth from '@/features/auth/components/Auth';
import Register from '@/features/auth/pages/Register';

// Nav
import mapRoutes from '../features/routing/RoutesRenderer';
import { clientItems } from './client.nav';
import mapAliases from '@/features/routing/AliasesRenderer';

const topBarItems = [
  {
    item: <NotificationsMenu />,
    mobileHidden: true,
  },
  {
    item: <CoinMenuItem />,
    mobileHidden: true,
  },
];

const App = () => (
  <AppMain>
    <SentryRoutes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="activate2fa" element={<ActivateTFA />} />
      <Route path="forgotpassword" element={<ForgotPassword />} />
      <Route path="resetpassword" element={<ResetPassword />} />

      <Route
        element={(
          <Auth>
            <Layout
              routes={clientItems}
              topbarItems={topBarItems}
              includeSearch
              diplayInitialModals
            />
          </Auth>
        )}
      >
        {mapRoutes({ routes: clientItems })}
        {mapAliases({ routes: clientItems })}

      </Route>
    </SentryRoutes>
  </AppMain>
);

export default App;
