import { useMemo } from 'react';
import { keepPreviousData, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';
import { useRawIntel } from '@/client/features/intelligence/services/getRawIntel';
import { useFinishedIntel } from '@/client/features/intelligence/services/getFinishedIntel';
import { useAuth } from '@/hooks/useAuth';

import { openapiClient, safePromise } from '@/lib/openapi-fetch';
import {
  combineAggEntries,
  fillMissingDates,
  normalizeAggEntry,
  unifiedApiAggToDonutOption,
  unifiedApiAggToTimelineOption,
  unifiedApiToGradientOption,
} from '@/components/content/chart/utils/transformations';

import { unifiedApiKeys } from '@/services/keys';
import { createOverviewAttckObject } from '@/client/features/overviewattck/utils/createOverviewObject';
import { vulnerabilitiesKeys } from './keys';
import { INTEL_ENDPOINTS } from '@/client/features/intelligence/services/keys';
import { isNotNull } from '@/utils';

import type { UnifiedApiAggResponse, UnifiedApiAggs, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { NonUndefined } from '@/types/general';
import type { FetchError } from '@/types/api';

function vulnerabilitiesResourceFetchFn(
  id: number,
) {
  return openapiClient.GET(
    '/catalog/vulnerabilities/{resource_id}',
    {
      params: {
        path: { resource_id: id },
      },
      headers: { source: 'entity' },
    },
  );
}

async function vulnerabilitiesEntityFetchFn(id: number) {
  const promises = await Promise.all([
    safePromise(vulnerabilitiesResourceFetchFn(id)),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.finished,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `vulnerabilities.id:${id}`,
              'populate_catalog:true',
            ],
            agg: [
              // Relationships
              'threat_actor_intent',
              'threat_actor_capability',
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'locations',

              // GFX
              'threat_actors limit:5',
              'malware_kits limit:5',
            ],
          },
        },
      },
    )),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.raw,
      {
        params: {
          query: {
            length: 0,
            filter: [`vulnerabilities.id:${id}`],
            agg: [
              // Relationships
              'threat_actor_motivation',
              'threat_actor_intent',
              'threat_actor_capability',
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'locations',

              // GFX
              'threat_actors limit:5',
              'malware_kits limit:5',
            ],
          },
        },
      },
    )),
  ]);

  if (promises[0].error) {
    throw new Error(promises[0].error);
  }

  if (promises.every((p) => isNotNull(p.error))) {
    throw new Error('Failed to fetch sources entity');
  }

  return promises;
}

type VulnerabilitiesContext = {
  relevant_industries: ReturnType<typeof normalizeAggEntry>;
  relevant_technologies: ReturnType<typeof normalizeAggEntry>;
  relevant_locations: ReturnType<typeof normalizeAggEntry>;
  threat_actor_motivation: ReturnType<typeof normalizeAggEntry>;
  threat_actor_intent: ReturnType<typeof normalizeAggEntry>;
  threat_actor_capability: ReturnType<typeof normalizeAggEntry>;
  malware_kits: ReturnType<typeof normalizeAggEntry>;
  threat_actors: ReturnType<typeof normalizeAggEntry>;
  top5ThreatActors: ReturnType<typeof unifiedApiToGradientOption>;
  top5MalwareKits: ReturnType<typeof unifiedApiToGradientOption>;
};

export type VulnerabilitiesResourceFetchResponse = NonUndefined<
Awaited<ReturnType<typeof vulnerabilitiesResourceFetchFn>>['data']
>;

export type VulnerabilitiesEntity = VulnerabilitiesResourceFetchResponse & VulnerabilitiesContext;

export function vulnerabilitiesEntityQuery(
  id: number,
  enabled?: boolean,
): UseQueryOptions<Awaited<ReturnType<typeof vulnerabilitiesEntityFetchFn>>, FetchError, VulnerabilitiesEntity> {
  return {
    queryKey: unifiedApiKeys.ticket('/catalog/vulnerabilities', id),
    queryFn: () => vulnerabilitiesEntityFetchFn(id),
    select: (data) => {
      const [
        entity,
        finished,
        raw,
      ] = data;

      const finishedAggs = finished.data?.data as UnifiedApiAggResponse;
      const rawAggs = raw.data?.data as UnifiedApiAggResponse;

      const [
        threatActorIntent,
        threatActorCapability,
        threatActors,
        malwareKits,
        industries,
        technologies,
        locations,

        // GFX
        top5ThreatActors,
        top5MalwareKits,
      ] = finishedAggs.aggs;

      const [
        threatActorMotivationRaw,
        threatActorIntentRaw,
        threatActorCapabilityRaw,
        threatActorsRaw,
        malwareKitsRaw,
        industriesRaw,
        technologiesRaw,
        locationsRaw,

        // GFX
        top5ThreatActorsRaw,
        top5MalwareKitsRaw,
      ] = rawAggs.aggs;

      const contextData = {
        threat_actor_motivation: normalizeAggEntry(threatActorMotivationRaw),
        threat_actor_intent: normalizeAggEntry(combineAggEntries(threatActorIntent, threatActorIntentRaw)),
        threat_actor_capability: normalizeAggEntry(combineAggEntries(threatActorCapability, threatActorCapabilityRaw)),
        threat_actors: normalizeAggEntry(combineAggEntries(threatActors, threatActorsRaw)),
        malware_kits: normalizeAggEntry(combineAggEntries(malwareKits, malwareKitsRaw)),
        relevant_industries: normalizeAggEntry(combineAggEntries(industries, industriesRaw)),
        relevant_technologies: normalizeAggEntry(combineAggEntries(technologies, technologiesRaw)),
        relevant_locations: normalizeAggEntry(combineAggEntries(locations, locationsRaw)),

        // GFX
        top5ThreatActors: unifiedApiToGradientOption(combineAggEntries(top5ThreatActors, top5ThreatActorsRaw)),
        top5MalwareKits: unifiedApiToGradientOption(combineAggEntries(top5MalwareKits, top5MalwareKitsRaw)),
      };

      const entityRawIntel = Array.isArray(entity.data?.data?.raw_intel) ? entity.data?.data?.raw_intel : [];

      return {
        ...(entity.data?.data as NonUndefined<VulnerabilitiesResourceFetchResponse>),
        ...contextData,
        attcks_overview: createOverviewAttckObject(entityRawIntel.flatMap((raw) => (
          Array.isArray(raw.attcks) ? raw.attcks : []
        ))),
      };
    },
    enabled,
  };
}

function useVulnerabilities() {
  const service = useCreateUnifiedApiService('/catalog/vulnerabilities/', openapiClient);

  return {
    list: service.list,
  };
}

export function useVulnerabilitiesEntity(id: number) {
  return useQuery(vulnerabilitiesEntityQuery(id));
}

export function useVulnerabilitiesTable(
  {
    filter,
    ...params
  }: Partial<UnifiedApiParams>,
) {
  const auth = useAuth();
  return useVulnerabilities().list({
    placeholderData: keepPreviousData,
    queryKey: vulnerabilitiesKeys.table,
    select: (raw) => {
      const { data } = raw;

      return {
        ...data,
        gfx: {
          typeDonut: unifiedApiAggToDonutOption(data?.aggs[0] as UnifiedApiAggs),
          priorityDonut: unifiedApiAggToDonutOption(data?.aggs[1] as UnifiedApiAggs),
        },
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        filter: [
          'raw_intel:*',
          ...(filter ?? []),
        ],
        agg: [
          'type',
          'priority',
        ],
        ...params,
      },
    },
    headers: { source: 'table' },
  });
}

export function useVulnerabilitiesGFX(filter: UnifiedApiParams['filter']) {
  const auth = useAuth();

  const finishedIntel = useFinishedIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => vulnerabilitiesKeys.gfx(INTEL_ENDPOINTS.finished, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        technologies,
        technologiesTimeline,
        vulnerabilities,
      ] = data.aggs;

      return {
        technologies,
        technologiesTimeline,
        vulnerabilities,
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        length: 0,
        filter: [
          'vulnerabilities:{name:*}',
          'populate_catalog:true',
          ...(filter ?? []),
        ],
        agg: [
          'technologies limit:10',
          'month(published_at) technologies order:asc',
          'vulnerabilities limit:5',
        ],
      },
    },
  });

  const rawIntel = useRawIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => vulnerabilitiesKeys.gfx(INTEL_ENDPOINTS.raw, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        technologies,
        technologiesTimeline,
        vulnerabilities,
      ] = data.aggs;

      return {
        technologies,
        technologiesTimeline,
        vulnerabilities,
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        length: 0,
        filter: [
          'vulnerabilities:{name:*}',
          ...(filter ?? []),
        ],
        agg: [
          'technologies limit:10',
          'month(published_at) technologies order:asc',
          'vulnerabilities limit:5',
        ],
      },
    },
  });

  const data = useMemo(() => {
    if (!finishedIntel.data && !rawIntel.data) return undefined;

    const {
      technologies,
      technologiesTimeline,
      vulnerabilities,
    } = finishedIntel.data || {};

    const {
      technologies: technologiesRaw,
      technologiesTimeline: technologiesTimelineRaw,
      vulnerabilities: vulnerabilitiesRaw,
    } = rawIntel.data || {};

    const top10Technologies = unifiedApiToGradientOption(combineAggEntries(technologiesRaw, technologies).slice(0, 10));
    const top10Set = new Set(top10Technologies.dimensions);
    const technologiesTimelineOption = unifiedApiAggToTimelineOption(
      fillMissingDates(
        combineAggEntries(technologiesTimelineRaw, technologiesTimeline).filter((item) => top10Set.has(item.key[2])),
      ),
    );
    return {
      top10Technologies,
      technologiesTimelineOption,
      vulnerabilitiesDonut: unifiedApiToGradientOption(combineAggEntries(vulnerabilitiesRaw, vulnerabilities)),
    };
  }, [
    finishedIntel.data,
    rawIntel.data,
  ]);

  return {
    isPending: finishedIntel.isPending || rawIntel.isPending,
    isError: finishedIntel.isError || rawIntel.isError,
    isFetching: finishedIntel.isFetching || rawIntel.isFetching,
    error: finishedIntel.error || rawIntel.error,
    data,
  };
}
