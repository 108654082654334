import DeferredDrawerLink from '@/client/features/drawerlink/DeferredDrawerLink';
import BlockedLink from '@/client/features/blockedlink/BlockedLink';

import { attcksEntityQuery } from '../services/attcks';
import { attckDrawer } from '../configs/attcks.drawer';
import { CATALOGS } from '@/constants/permissions';

interface AttcksDrawerProps {
  id: number;
  name: string;
  amount?: number;
}

const AttcksDrawer = ({
  id,
  name,
  amount,
}: AttcksDrawerProps) => {
  const url = `/catalogs/attck/entity/${id}`;
  const displayName = amount ? `${name} (${amount})` : name;

  return (
    <BlockedLink id={id} name={displayName} modules={CATALOGS.sources.modules}>
      <DeferredDrawerLink
        id={id}
        value={displayName}
        content={attckDrawer}
        query={attcksEntityQuery(id)}
        link={url}
      />
    </BlockedLink>
  );
};

export default AttcksDrawer;
