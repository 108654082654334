import type { Falsy } from '@/types/general';

const getCapabilityNumber = (capability: string) => {
  switch (capability) {
    case 'low':
      return 1;
    case 'medium':
      return 2;
    case 'high':
      return 3;
    case 'advanced':
      return 4;

    default:
      return 0;
  }
};

export function getTaCapabilityAsNumber(capability: Array<string> | Falsy) {
  if (!capability || !capability.length) return 0;

  const capabilityArray = capability.map((item) => (item ? item.trim().toLowerCase() : ''));

  return Math.max(...capabilityArray.map(getCapabilityNumber));
}
