import { useQuery } from '@tanstack/react-query';

import { openapiClient } from '@/lib/openapi-fetch';
import { abbreviateNumber } from '@/utils/number';
import { unifiedApiKeys } from '@/services/keys';

import { ERROR_CLASSES } from '@/types/api';

import type { ResultTabProps } from '../types';
import type { UnifiedApiResult } from '@/types/mercury-data-types/unifiedapi';

const ResultTab = ({
  result: { title, endpoint, filterBuilder },
  q,
  filters,
}: ResultTabProps) => {
  const params = {
    start: 0,
    filter: [...filterBuilder(filters), q],
    length: 0,
  };

  const countQuery = useQuery({
    queryKey: unifiedApiKeys.records(endpoint, params),
    queryFn: async () => (
      openapiClient.GET(
        endpoint,
        {
          params: {
            query: params,
          },
        },
      )
    ),
    select: (data) => data.data as UnifiedApiResult,
  });

  const manyOrNumber = (
    count: number = 0,
    limit: number = 10000,
  ) => (
    limit && count >= limit ? `+${abbreviateNumber(limit)}` : abbreviateNumber(count)
  );

  const count = () => {
    if (countQuery.isError) {
      if (countQuery.error?.code === 402) {
        return manyOrNumber(countQuery.error.details?.count);
      }

      if (countQuery.error?.code === 400 && countQuery.error.details?.class === ERROR_CLASSES.QUERY_ATTRIBUTE_ERROR) {
        return 0;
      }

      return '?';
    }

    if (countQuery.isPending || countQuery.isFetching) {
      return '?';
    }

    return manyOrNumber(countQuery.data?.count);
  };

  return (
    <div>
      {title} ({count()})
    </div>
  );
};

export default ResultTab;
