import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { openapiClient, openApiFetch } from '@/lib/openapi-fetch';
import { unifiedApiKeys } from '@/services/keys';

import type { UnifiedApiAggResponse, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { FinishedIntelTable,
  FinishedIntelTableRecord,
  FinishedIntelTicket } from '../types';
import { INTEL_ENDPOINTS, intelligenceKeys } from './keys';
import { unifiedApiAggToDonutOption,
  unifiedApiAggToTimelineOption } from '@/components/content/chart/utils/transformations';
import { getRegions } from '../utils';
import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';
import { createOverviewAttckObject } from '../../overviewattck/utils/createOverviewObject';
import { useAuth } from '@/hooks/useAuth';

export const finishedIssueQuery = (
  issueId: number,
  hasPermissions?: boolean,
) => {
  const baseIncludes = ['user_state', 'finished_intel', 'incidents', 'raw_intel', 'rfis', 'takedowns'];

  return {
    queryKey: unifiedApiKeys.ticket(INTEL_ENDPOINTS.finished, issueId),
    queryFn: () => openApiFetch(
      `${INTEL_ENDPOINTS.finished}{resource_id}`,
      {
        params: {
          path: { resource_id: issueId },
          query: {
            include: hasPermissions ? [...baseIncludes, 'attcks'] : baseIncludes,
          },
        },
        headers: { source: 'issue' },
      },
    ),
    select: (finished: any) => {
      const data = finished as unknown as Omit<
      FinishedIntelTicket, 'attachments' | 'attcks_overview'
      >;

      return {
        ...data,
        attcks_overview: createOverviewAttckObject(Array.isArray(data.attcks) ? data.attcks : []),
      };
    },
  };
};

export const useFinishedIntel = () => {
  const service = useCreateUnifiedApiService(INTEL_ENDPOINTS.finished, openapiClient);

  return {
    list: service.list,
    summary: service.summary,
  };
};

export const finishedIntelTableSelect = (record: FinishedIntelTableRecord) => ({
  ...record,
  regions: getRegions(record.locations),
});

export const useFinishedIntelTicket = (id: number, hasPermissions?: boolean) => (
  useQuery(finishedIssueQuery(id, hasPermissions))
);

export const useFinishedIntelTable = (params: Partial<UnifiedApiParams> = {}) => {
  const auth = useAuth();

  return useFinishedIntel().list({
    placeholderData: keepPreviousData,
    select: (raw) => {
      const data = raw.data as unknown as FinishedIntelTable;

      return {
        records: data?.records.map(finishedIntelTableSelect),
        count: data?.count,
      };
    },
    queryKey: intelligenceKeys.finishedTable,
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        ...params,
        include: [
          'locations',
          'user_state',
        ],

      },
    },
    headers: { source: 'table' },
  });
};

export const useFinishedIntelGfx = () => (
  useFinishedIntel().list({
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;
      const [groupByProductType, productTypeTimeline] = data.aggs;

      return {
        donut: unifiedApiAggToDonutOption(groupByProductType),
        timeline: unifiedApiAggToTimelineOption(productTypeTimeline),
      };
    },
    queryKey: intelligenceKeys.finishedVisualizations,
  }, {
    params: {
      query: {
        length: 0,
        filter: [
          'product_type:*',
          'published_at>now-1y',
        ],
        agg: [
          'product_type',
          'month(published_at) product_type order:asc',
        ],
      },
    },
    headers: { source: 'gfx' },
  })
);
