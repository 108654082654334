import { useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useAdminRedirect } from './useAdminRedirection';
import { useAuthType } from './useAuthType';
import { useAuthInfoMutation } from './useAuthInfoMutation';
import { useGetTokenInfoMutation } from '../services/getAuthInfo';
import { useAuthMutation } from '../services/login';
import { useSession } from '@/context/SessionContext';

import { isServiceUser } from '@/utils/usersRole';

import type { OnSubmitFunc } from '@/types/form';
import type { LoginFormFields, PrecheckReturn } from '../types';

export const useSignIn = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setTokenInfo } = useAuthInfoMutation();
  const { startSession } = useSession();
  const { hasAdminRedirect, redirectAdmin } = useAdminRedirect();

  const { isPending: isSigningIn, mutate: signInMutate } = useAuthMutation();
  const { mutate: getTokenInfo, isPending: isGettingUserInfo } = useGetTokenInfoMutation();

  const { hash } = location;
  const redirectParam = searchParams.get('redirect');
  const state = location.state as { from: Location };
  const from = state?.from || '/';

  const loginPageState = location.state as {
    modal_text: string;
    modal_title: string;
    avoidPrecheck: boolean;
  };

  const {
    precheck,
    isUnauthorized,
    isGettingType,
    setPrecheckData,
  } = useAuthType(loginPageState?.avoidPrecheck);

  const onCancelOrBack = () => {
    setPrecheckData(null);
  };

  const onUnauthorizedPrecheck = (data: PrecheckReturn) => {
    setPrecheckData(data);
  };

  const loadingSignIn = isSigningIn || isGettingUserInfo;

  const isLoginReady = Boolean(!isUnauthorized && precheck);

  const checkingRef = useRef<HTMLDivElement | null>(null);
  const loginRef = useRef<HTMLDivElement | null>(null);
  const nodeRef = isLoginReady ? loginRef : checkingRef;

  const signIn: OnSubmitFunc<LoginFormFields> = (formData, setGeneralError, setSuccessMessage) => {
    const redirectToParam = (redirectTo: string) => {
      window.location.replace(redirectTo);
    };

    const force2fa = (email: string) => {
      navigate(`/activate2fa?username=${encodeURIComponent(email)}`, { state });
    };

    const forceServiceUser = () => {
      setSuccessMessage(`
        You are trying to log in with a service user.
        These users don't have access to Mercury.
        Contact your dedicated Customer Success Manager if you need assistance
      `);
    };

    signInMutate(formData, {
      onSuccess: () => {
        getTokenInfo('', {
          onSuccess: (token) => {
            if (redirectParam) {
              redirectToParam(`${redirectParam}${hash}`);
              return;
            }

            if (token.force_2fa && !token.enabled_2fa) {
              force2fa(token.sub);
              return;
            }

            if (hasAdminRedirect(token)) {
              Sentry.setUser({ email: token.sub, id: token.user_id });
              redirectAdmin();
              return;
            }

            if (isServiceUser(token.role)) {
              forceServiceUser();
              return;
            }

            Sentry.setUser({ email: token.sub, id: token.user_id });
            setTokenInfo(token);
            startSession();
            navigate(from, { replace: true });
          },
          onError: (error) => {
            setGeneralError(error?.message);
          },
        });
      },
      onError: (error) => {
        setGeneralError({ ...error, message: error?.responseError });
      },
    });
  };

  return {
    signIn,
    loadingSignIn,
    loginPageState,
    onCancelOrBack,
    nodeRef,
    isLoginReady,
    authInfo: precheck,
    isGettingType,
    redirectParam,
    onUnauthorizedPrecheck,
  };
};
