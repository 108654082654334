interface Options {
  method?: string;
  headers?: HeadersInit;
  body?: any;
  priority?: RequestPriority;
}

export interface ContextVariables {
  source?: string | null;
}

export interface FetchDataArguments {
  endpoint: string;
  options?: Options;
  unauthorized?: string;
  error?: string;
  noContent?: string;
  requestLimit?: string;
  resolveOnNoContent?: boolean;
  context?: ContextVariables;
  useCompleteEndpoint?: boolean;
}

export const ERROR_CLASSES = {
  QUERY_ATTRIBUTE_ERROR: 'QueryAttributeError',
} as const;

type ErrorClasses = typeof ERROR_CLASSES[keyof typeof ERROR_CLASSES];

export interface FetchError {
  code: number;
  message: string;
  responseError: string;
  details?: Record<string, any> & { class?: ErrorClasses };
  headers?: Headers;
}

export type PostPutMethod = 'POST' | 'PUT' | 'PATCH';

export type ClientId = string;

export type UserId = number;
