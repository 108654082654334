import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, Text } from '@mantine/core';

import TwoFAModal from '../../usersettings/components/profileinfo/TwoFAModal';
import Title from '@/components/content/title/Title';

import { useTfa } from '../../usersettings/hooks/useTfa';
import { useLogout } from '../services/logout';
import { INVALID_FORCED_2FA } from '@/constants/text/english';

import classes from './Auth.module.css';
import { useToast } from '@/hooks/useToast';

const ActivateTFA = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    serverLogout,
    clientLogout,
  } = useLogout();
  const username = searchParams.get('username') as string;
  const { createToast } = useToast();
  const {
    mutate: logout,
    isPending: isLoginOut,
  } = serverLogout();

  const {
    generateTFA,
    activateTFA,
    TFAData,
    disableButton,
  } = useTfa({
    onActivateSuccess: () => {
      logout('', {
        onSuccess: () => {
          clientLogout();
        },
        onError: () => {
          createToast('We had an issue redirecting you. Please reload the page and try again.', 'error');
        },
      });
    },
    onGenerateError: () => {
      navigate('/login', {
        state: {
          modal_title: 'We are sorry, something went wrong.',
          avoidPrecheck: true,
          modal_text: INVALID_FORCED_2FA,
        },
      });
    },
    username,
  });

  useEffect(() => {
    if (!username) {
      navigate('/login');
      return;
    }

    generateTFA();
  }, []);

  return (
    <Stack align="center" justify="center" classNames={{ root: classes.wrapper }}>
      <div className={classes.content}>
        <Title order={1}>2-Factor Authentication needs to be setup</Title>

        <div>
          <Text>You have been requested to activate 2-factor authentication by your organisation.</Text>
          <Text>Please set it up to continue using Mercury.</Text>
        </div>

        {TFAData && (
          <TwoFAModal
            enabled={false}
            data={TFAData}
            tfaAction={activateTFA}
            disableAction={disableButton || isLoginOut}
          />
        )}
      </div>
    </Stack>
  );
};

export default ActivateTFA;
