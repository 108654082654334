export const aggregateFromMetisRelationship = <T extends any>(data: Array<T>, param: keyof T) => {
  if (!data || !data.length) return {};

  const result: Record<string, string> = {};

  data.forEach((item) => {
    const value = item[param];

    if (typeof value === 'string') {
      if (!result[value]) {
        result[value] = value;
      }
    } else if (Array.isArray(value)) {
      value.forEach((val) => {
        if (!result[val]) {
          result[val] = val;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      const values = value as Record<string, string>;
      Object.keys(values).forEach((key) => {
        if (!result[key]) {
          result[key] = values[key];
        }
      });
    }
  });

  return result;
};
