import { Text, Stack } from '@mantine/core';

import Indicator from './Indicator';
import { useRiskIndicator } from './useRiskIndicator';

import type { NormalizedRedmineField } from '../catalogs/types';
import type { ElementProps } from '../issue/types/modulesv2';

interface RiskIndicatorsProps
  extends ElementProps<{
    technologies: NormalizedRedmineField;
    locations: NormalizedRedmineField;
    industry_sectors: NormalizedRedmineField;
  }> {
}

const RiskIndicators = ({
  getValue,
}: RiskIndicatorsProps) => {
  const {
    technologies,
    locations,
    industry_sectors: industries,
  } = getValue();

  const {
    hasIndicator: hasRelevantIndustries,
    relevantContext: relevantIndustries,
  } = useRiskIndicator({ context: industries, indicator: 'relevant_industry_sectors' });
  const {
    hasIndicator: hasRelevantLocations,
    relevantContext: relevantLocations,
  } = useRiskIndicator({ context: locations, indicator: 'relevant_locations' });
  const {
    hasIndicator: hasRelevantTechnologies,
    relevantContext: relevantTechnologies,
  } = useRiskIndicator({ context: technologies, indicator: 'relevant_technologies' });

  const hasIndicators = hasRelevantIndustries || hasRelevantLocations || hasRelevantTechnologies;
  const hasValues = industries?.length || locations?.length || technologies?.length;

  if (!hasValues || !hasIndicators) {
    return (
      <Text>No relevancy found</Text>
    );
  }

  return (
    <Stack gap={5}>
      <Indicator
        hasIndicator={hasRelevantIndustries}
        context={relevantIndustries}
        text="Targeting Your Industry"
      />

      <Indicator
        hasIndicator={hasRelevantLocations}
        context={relevantLocations}
        text="Targeting Your Region"
      />

      <Indicator
        hasIndicator={hasRelevantTechnologies}
        context={relevantTechnologies}
        text="Relevant to Your Technology"
      />
    </Stack>
  );
};

export default RiskIndicators;
