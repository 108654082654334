import { Transition } from '@mantine/core';

import DropdownMenu from '@/components/layout/nav/topbar/DropdownMenu';
import NotificationsBadgeIcon from './components/BadgeIcon';
import Notifications from './components/Notifications';
import Tabs from '@/components/content/tabs/Tabs';
import NotificationsReadAll from './components/ReadAll';

import { useGetNotifications } from './services/getNotifications';
import classes from './Notifications.module.css';

const NotificationsMenu = () => {
  const query = useGetNotifications();
  const total = query.new.total + query.unread.total;

  const tabs = [
    {
      tab: `Unread (${query.unread.total})`,
      val: 'unread',
    },
    {
      tab: `New Incidents (${query.new.total})`,
      val: 'new',
    },
  ];

  return (
    <Transition
      mounted={!query.isPending && !query.isError}
      transition="slide-left"
      duration={300}
      timingFunction="ease"
    >
      {(styles) => (
        <div style={styles}>
          <DropdownMenu
            icon={<NotificationsBadgeIcon totalNotifications={total} />}
            size={335}
            hideArrow={false}
          >
            <div className={classes.wrapper}>
              <h1 className={classes.title}>Notifications</h1>

              <Tabs
                tabs={tabs}
                id="notifications-menu"
                defaultValue="new"
                small
                justify="space-between"
                mt={25}
                grow
              >
                <Notifications
                  items={query.unread.values}
                  footer={<NotificationsReadAll />}
                  emptyMsg="unread"
                />

                <Notifications
                  items={query.new.values}
                  emptyMsg="unalcknowledged"
                />
              </Tabs>
            </div>
          </DropdownMenu>
        </div>
      )}
    </Transition>
  );
};

export default NotificationsMenu;
