export enum Cardinalities {
  range = 'range',
  multiple = 'multiple',
  single = 'single',
  listsingle = 'list_single',
}

const FrontendCategories = {
  context: 'context_filter',
  entity: 'entity_filter',
} as const;

type FrontendCategoriesTypes = typeof FrontendCategories[keyof typeof FrontendCategories];

export const FiltersTypes = {
  date: 'date',
  string: 'string',
  category: 'category',
  boolean: 'boolean',
} as const;

type TFiltersTypes = typeof FiltersTypes[keyof typeof FiltersTypes];

interface IMeta {
  name: string;
  label: string;
  description: string;
  type: TFiltersTypes;
  cardinality: Cardinalities;
  frontend_category?: FrontendCategoriesTypes;
  default_choice?: string;
  choices?: Array<Choice>;
  non_meta?: boolean;
}

type Range = {
  min: string;
  max: string;
};

export interface TDate extends IMeta {
  range: Range;
}

type Choice = {
  value: string | number;
  label: string;
  description: string;
  parent_id: number | string | null;
  parent_cat?: string | null;
};

export interface Multiple extends IMeta {
  choices: Array<Choice>;
  layout: 'checkbox';
}

export interface SingleSelect extends IMeta {
  choices: Array<Choice>;
}

export interface Single extends IMeta { }

type Filters = TDate | Multiple | Single | SingleSelect;

export type FiltersMeta = Array<Filters>;
