import { Route } from 'react-router-dom';

import type { RoutePathDefinition } from '@/types/routes';
import Redirect from '@/components/content/redirect/Redirect';
import { ReactElement } from 'react';

interface RouteRendererProps {
  routes: Array<RoutePathDefinition>;
  path?: string;
}

const mapAliases = ({
  routes,
  path,
}: RouteRendererProps) => routes.flatMap((route) => {
  // eslint-disable-next-line no-nested-ternary
  const basePath = path ? `${path}/${route.path}` : route.layout ? path : route.path;
  const aliasRoutes: Array<ReactElement> = [];

  if (route.aliases) {
    route.aliases.forEach((alias) => {
      aliasRoutes.push(
        <Route key={alias} path={alias} element={<Redirect to={`/${basePath ?? ''}`} />} />,
      );
    });
  }

  if (route.children) {
    aliasRoutes.push(...mapAliases({
      routes: route.children,
      path: basePath,
    }));
  }

  return aliasRoutes;
});

export default mapAliases;
