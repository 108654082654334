import { useNavigate } from 'react-router-dom';
import { Stack, Tooltip } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import ResultsWrapper from './ResultsWrapper';
import MercuryPagination from '@/components/content/pagination/Pagination';
import MercuryButton from '@/components/content/button/Button';

import { useMercuryPagination } from '@/hooks/useMercuryPagination';
import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';
import { unifiedApiKeys } from '@/services/keys';
import { openapiClient } from '@/lib/openapi-fetch';

import { MODULES } from '@/constants/permissions';
import type { ResultProp } from '../types';
import type { UnifiedApiResult } from '@/types/mercury-data-types/unifiedapi';

import AnalyticsDashboard from '@/assets/icons/sidebar/analytics-dashboard.svg';

const Results = ({
  endpoint,
  filterBuilder,
  include,
  template: Template,
  query,
  filters,
  title,
  canRedirectToAnalytics,
  q,
}: ResultProp) => {
  const [page, { next, previous, handlePagination }] = useMercuryPagination();
  const canAccessAnalytics = useMercuryPermissions().hasAccess(MODULES.analyticsdashboard);

  const navigate = useNavigate();

  const LENGTH = 10;

  const params = {
    start: page * LENGTH,
    filter: [...filterBuilder(filters), query],
    length: LENGTH,
    include,
  };

  const results = useQuery({
    queryKey: unifiedApiKeys.records(endpoint, params),
    queryFn: async () => (
      openapiClient.GET(
        endpoint,
        {
          params: {
            query: params,
          },
          headers: { source: 'global_search' },
        },
      )
    ),
    select: (data) => {
      const unifiedData = data.data as UnifiedApiResult;

      return {
        ...data.data as unknown as UnifiedApiResult,
        pages: Math.ceil((unifiedData.count) / LENGTH),
      };
    },
  });

  const redirect = () => {
    const processedFilters = filterBuilder(filters);

    // eslint-disable-next-line max-len
    navigate(`/analytics?query[raw]=${q}${processedFilters.length
      ? ` AND ${processedFilters.join(' AND ')}`
      : ''}&tab=Signals`);
  };

  return (
    <Stack gap={20}>
      {canRedirectToAnalytics && (
        <Tooltip disabled={canAccessAnalytics} label="You need access to the MODULE_ANALYTICS module to access">
          <MercuryButton
            ml="auto"
            onClick={redirect}
            rightSection={<AnalyticsDashboard width={15} height={15} />}
            disabled={!canAccessAnalytics}
          >
            Open in Analytics Dashboard
          </MercuryButton>
        </Tooltip>
      )}

      <ResultsWrapper
        isPending={results.isPending}
        isFetching={results.isFetching}
        isError={results.isError}
        error={results.error}
        refetch={results.refetch}
        count={results.data?.count || 0}
        noMatchText={title}
      >
        {Template && results.data?.records?.map((record, index) => (
          <Template data={record} key={record.id || `result-p${page}-${index}`} />
        ))}

        <MercuryPagination
          value={page + 1}
          total={results.data?.pages as number}
          onChange={(p) => handlePagination(p - 1)}
          onNextPage={next}
          onPreviousPage={previous}
        />
      </ResultsWrapper>
    </Stack>
  );
};

export default Results;
