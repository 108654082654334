import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openapiClient, safePromise } from '@/lib/openapi-fetch';

import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';
import { unifiedApiKeys } from '@/services/keys';
import { personasKeys } from './keys';

import { INTEL_ENDPOINTS } from '@/client/features/intelligence/services/keys';
import { isNotNull, mergeUniqueArray } from '@/utils';
import { combineAggEntries, normalizeAggEntry } from '@/components/content/chart/utils/transformations';

import type { Schema, UnifiedApiAggResponse, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { FetchError } from '@/types/api';
import type { NonUndefined } from '@/types/general';

function usePersonas() {
  const service = useCreateUnifiedApiService('/catalog/personas/', openapiClient);

  return {
    list: service.list,
  };
}

function personasResourceFetchFn(
  id: number,
) {
  return openapiClient.GET(
    '/catalog/personas/{resource_id}',
    {
      params: {
        path: { resource_id: id },
      },
      headers: { source: 'entity' },
    },
  );
}

async function personasEntityFetchFn(id: number) {
  const promises = await Promise.all([
    safePromise(personasResourceFetchFn(id)),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.finished,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `personas.id:${id}`,
              'populate_catalog:true',
            ],
            agg: [
              // Relationships
              'threat_actor_capability',
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'locations',
              'sources',
              'companies',
            ],
          },
        },
      },
    )),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.raw,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `personas.id:${id}`,
            ],
            agg: [
              // Relationships
              'threat_actor_capability',
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'locations',
              'sources',
              'companies',
            ],
          },
        },
      },
    )),
  ]);

  if (promises[0].error) {
    throw new Error(promises[0].error);
  }

  if (promises.every((p) => isNotNull(p.error))) {
    throw new Error('Failed to fetch personas entity');
  }

  return promises;
}

type PersonaContext = {
  threat_actor_capability: ReturnType<typeof normalizeAggEntry>;
  threat_actors: ReturnType<typeof normalizeAggEntry>;
  malware_kits: ReturnType<typeof normalizeAggEntry>;
  relevant_industries: ReturnType<typeof normalizeAggEntry>;
  relevant_technologies: ReturnType<typeof normalizeAggEntry>;
  relevant_locations: ReturnType<typeof normalizeAggEntry>;
  sources: ReturnType<typeof normalizeAggEntry>;
  relevant_companies: ReturnType<typeof normalizeAggEntry>;
};

export type PersonasResourceFetchResponse = NonUndefined<Awaited<ReturnType<typeof personasResourceFetchFn>>['data']>;

export type PersonaEntity = PersonasResourceFetchResponse & PersonaContext;

export function personasEntityQuery(
  id: number,
  enabled?: boolean,
): UseQueryOptions<Awaited<ReturnType<typeof personasEntityFetchFn>>, FetchError, PersonaEntity> {
  return {
    queryKey: unifiedApiKeys.ticket('/catalog/personas/', id),
    queryFn: () => personasEntityFetchFn(id),
    select: (data) => {
      const [
        entity,
        finished,
        raw,
      ] = data;

      const finishedAggs = finished.data?.data as UnifiedApiAggResponse;
      const rawAggs = raw.data?.data as UnifiedApiAggResponse;

      const [
        threatActorCapability,
        threatActors,
        malwareKits,
        industries,
        technologies,
        locations,
        sources,
        companies,
      ] = finishedAggs?.aggs || [];

      const [
        threatActorCapabilityRaw,
        threatActorsRaw,
        malwareKitsRaw,
        industriesRaw,
        technologiesRaw,
        locationsRaw,
        sourcesRaw,
        companiesRaw,
      ] = rawAggs?.aggs || [];

      const contextData = {
        threat_actor_capability: normalizeAggEntry(combineAggEntries(threatActorCapability, threatActorCapabilityRaw)),
        threat_actors: normalizeAggEntry(combineAggEntries(threatActors, threatActorsRaw)),
        malware_kits: normalizeAggEntry(combineAggEntries(malwareKits, malwareKitsRaw)),
        relevant_industries: normalizeAggEntry(combineAggEntries(industries, industriesRaw)),
        relevant_technologies: normalizeAggEntry(combineAggEntries(technologies, technologiesRaw)),
        relevant_locations: normalizeAggEntry(combineAggEntries(locations, locationsRaw)),
        sources: normalizeAggEntry(combineAggEntries(sources, sourcesRaw)),
        relevant_companies: normalizeAggEntry(combineAggEntries(companies, companiesRaw)),
      };

      return {
        ...(entity.data?.data as NonUndefined<PersonasResourceFetchResponse>),
        ...contextData,
      };
    },
    enabled,
  };
}

export function usePersonasEntity(
  id: number,
  enabled?: boolean,
) {
  return useQuery(personasEntityQuery(id, enabled));
}

export function usePersonasTable(
  {
    filter = [],
    ...params
  }: Partial<UnifiedApiParams>,
) {
  return usePersonas().list({
    queryKey: personasKeys.table,
    select: (raw) => {
      const getFromRawIntel = (record: Schema<'PersonaResponse'>) => (
        Array.isArray(record.raw_intel) ? record.raw_intel.reduce((accu, curr) => {
          const {
            threat_actor_capability: threatActorCapability,
            threat_actor_intent: threatActorIntent,
            threat_actor_motivation: threatActorMotivation,
          } = curr;

          accu.threat_actor_capability = mergeUniqueArray(accu.threat_actor_capability, threatActorCapability);
          accu.threat_actor_intent = mergeUniqueArray(accu.threat_actor_intent, threatActorIntent);
          accu.threat_actor_motivation = mergeUniqueArray(accu.threat_actor_motivation, threatActorMotivation);

          return accu;
        }, {
          threat_actor_capability: [],
          threat_actor_intent: [],
          threat_actor_motivation: [],
        } as Record<
        'threat_actor_capability' | 'threat_actor_intent' | 'threat_actor_motivation',
        Array<string>
        >) : {});

      const data = {
        ...raw.data,
        records: raw.data?.records.map((record) => ({
          ...record,
          ...getFromRawIntel(record),
        })),
      };

      return data;
    },
  }, {
    params: {
      query: {
        filter: [
          'raw_intel:*',
          'description:*',
          ...filter,
        ],
        include: [
          'raw_intel',
        ],
        ...params,
      },
    },
    headers: { source: 'table' },
  });
}
