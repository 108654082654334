/* eslint-disable no-useless-escape */

import { ReactNode } from 'react';

export function removeSpecialCharsAndSpaces(str: string) {
  return str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/gi, '');
}

export function plural(count: number, onOne: ReactNode, onMany: ReactNode) {
  return count === 1 ? onOne : onMany;
}
