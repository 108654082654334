import { MouseEvent } from 'react';
import type { TableState } from '@tanstack/react-table';

import Linkv2 from '@/components/content/link/Linkv2';
import IssueIDLink from '../../issueIdLink/IssueIdLink';
import IssueWrapperV2 from '../../issue/components/IssueWrapper';

import { useModal } from '@/hooks/useModal';
import { useMSSP } from '@/hooks/useIsMSSP';
import { incidentModalModules } from '../configs/incident.modal';

import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';
import type { IncidentPageTableRecord } from '../types';

interface IncidentModalProps {
  tableState?: TableState;
  incident: IncidentPageTableRecord;
  inSearchResults?: boolean;
}

const IncidentModal = ({
  incident,
  tableState,
  inSearchResults,
}: IncidentModalProps) => {
  const { Modal, modalProps, openModal } = useModal();
  const { isSubclient } = useMSSP();
  const issueId = incident.id;

  const clickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    openModal();
  };

  return (
    <div>
      <Linkv2 href={`/incidentmanagement/alert/issue/${incident.id}`} onClick={clickHandler}>
        {issueId} {inSearchResults ? `| ${incident.name}` : ''}
      </Linkv2>

      <Modal
        {...modalProps}
        title={`${issueId} | ${incident.name}`}
        size="xl"
        link={(
          <IssueIDLink
            value={issueId}
            name="See more"
            tracker={mercuryTrackers[MercuryTrackersIds.alerts]}
            tableState={tableState}
            usePage
            useParent={inSearchResults}
          />
        )}
        centered
        alternativeBackground
      >
        <IssueWrapperV2
          top={incidentModalModules({ hideActions: isSubclient || inSearchResults })}
          data={incident}
          id={issueId}
          removePadding
        />
      </Modal>
    </div>
  );
};

export default IncidentModal;
