import { useSearchParams } from 'react-router-dom';
import { Box } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { MercuryCol, MercuryGrid } from '@/components/grid/Grid';
import MercuryTabs from '@/components/content/tabs/Tabs';
import Results from '../components/Results';
import Title from '@/components/content/title/Title';
import FullPageContent from '@/components/content/box/FullPageContent';
import NavBarSearchBar from '@/components/layout/nav/topbar/searchbar/NavBarSearchBar';
import ResultTab from '../components/ResultTab';
import SearchFilters from '../components/SearchFilters';

import { useMetaStore } from '@/hooks/useMetaStore';
import { results } from './results.config';

import classes from '../components/Results.module.css';

const baseState = { p: 0 };

const Search = () => {
  const [searchParams] = useSearchParams();
  const metaStore = useMetaStore({ baseState });
  const matches = useMediaQuery('(min-width: 62em)');

  const q = searchParams.get('q') ?? '';

  if (!q) {
    return (
      <FullPageContent>
        <Title order={2} ta="center">Find what you are looking for</Title>

        <NavBarSearchBar />
      </FullPageContent>
    );
  }

  const tabs = results.map((result) => ({
    val: result.tab,
    tab: (
      <ResultTab
        result={result}
        q={q}
        filters={metaStore.filters.state}
      />
    ),
  }));

  return (
    <MercuryGrid>
      <MercuryCol transparent>
        <Box maw={{ lg: '80%' }}>
          <Title order={1}>Showing Results for: <b>{q}</b></Title>

          <SearchFilters metaStore={metaStore} />

          <MercuryTabs
            tabs={tabs}
            orientation={matches ? 'vertical' : 'horizontal'}
            id="search-results"
            renderSelect={!matches}
            mt={25}
            urlObject={baseState}
            small
            saveTabInUrl
            sticky
          >
            {results.map((result) => (
              <div className={classes.tabContent} key={result.endpoint}>
                <Results
                  query={q}
                  filters={metaStore.filters.state}
                  q={q}
                  {...result}
                />
              </div>
            ))}
          </MercuryTabs>
        </Box>
      </MercuryCol>
    </MercuryGrid>
  );
};

export default Search;
