import { postMutation } from '@/lib/react-query/mutate';
import { METIS_TASK_NAME, useMetisTriggerReplication } from '@/services/unifiedapi/replication';
import { notificationsKeys } from './keys';
import { MercuryTrackersIds } from '@/constants/mercuryPages';
import { useQueryActions } from '@/hooks/useQueryActions';
import { useUserId } from '@/hooks/useUserId';

export const useNotificationsMarkAllAsReadMutation = () => {
  const userId = useUserId();
  const { mutate: replicate } = useMetisTriggerReplication(METIS_TASK_NAME.ISSUE);
  const { invalidateQueries } = useQueryActions();

  return postMutation({
    endpoint: `mercury/notifications/read/${userId}`,
    mutationOptions: {
      onSuccess: () => {
        replicate('', {
          onSuccess: () => {
            invalidateQueries([
              notificationsKeys.notificationUnread(MercuryTrackersIds.finished),
              notificationsKeys.notificationUnread(MercuryTrackersIds.raw),
              notificationsKeys.notificationUnread(MercuryTrackersIds.vulnerabilities),
            ]);
          },
        });
      },
    },
  });
};
