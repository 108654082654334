import DeferredDrawerLink from '@/client/features/drawerlink/DeferredDrawerLink';
import BlockedLink from '@/client/features/blockedlink/BlockedLink';

import { personasEntityQuery } from '../services/personas';
import { personasDrawer } from '../configs/personas.drawer';
import { CATALOGS } from '@/constants/permissions';

interface PersonasDrawerProps {
  id: number;
  name: string;
  amount?: number;
}

const PersonasDrawer = ({
  id,
  name,
  amount,
}: PersonasDrawerProps) => {
  const url = `/catalogs/personas/entity/${id}`;
  const displayName = amount ? `${name} (${amount})` : name;

  return (
    <BlockedLink id={id} name={displayName} modules={CATALOGS.personas.modules}>
      <DeferredDrawerLink
        id={id}
        value={displayName}
        content={personasDrawer}
        query={personasEntityQuery(id)}
        link={url}
      />
    </BlockedLink>
  );
};

export default PersonasDrawer;
