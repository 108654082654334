import DeferredDrawerLink from '@/client/features/drawerlink/DeferredDrawerLink';
import BlockedLink from '@/client/features/blockedlink/BlockedLink';

import { vulnerabilitiesEntityQuery } from '../services/vulnerabilities';
import { vulnerabilitiesDrawer } from '../configs/vulnerabilities.drawer';
import { CATALOGS } from '@/constants/permissions';

interface VulnerabilitiesDrawerProps {
  id: number;
  name: string;
  amount?: number;
}

const VulnerabilitiesDrawer = ({
  id,
  name,
  amount,
}: VulnerabilitiesDrawerProps) => {
  const url = `/catalogs/vulnerabilities/entity/${id}`;
  const displayName = amount ? `${name} (${amount})` : name;

  return (
    <BlockedLink id={id} name={displayName} modules={CATALOGS.vulnerabilities.modules}>
      <DeferredDrawerLink
        id={id}
        value={displayName}
        content={vulnerabilitiesDrawer}
        query={vulnerabilitiesEntityQuery(id)}
        link={url}
      />
    </BlockedLink>
  );
};

export default VulnerabilitiesDrawer;
