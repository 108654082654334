import { unifiedApiKeys } from '@/services/keys';

import type { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';

export const malwareKitsKeys = {
  table: (params?: Partial<UnifiedApiParams>) => (
    ['table', ...unifiedApiKeys.records('/catalog/malware_kits/', params)]
  ) as const,

  gfx: (context: string, params?: Partial<UnifiedApiParams>) => (
    ['malware_kits', 'gfx', ...unifiedApiKeys.records(context, params)]
  ) as const,
};
