import { getKeys, getValues } from '@/utils';

import type { Overviews } from '../types';
import type { Schema } from '@/types/mercury-data-types/unifiedapi';
import { uniqueBy } from '@/utils/uniqueBy';

export const createOverviewAttckObject = (
  attcks: Array<Schema<'AttckResponse'>>,
) => uniqueBy(attcks, 'name').reduce((accu, attck) => {
  const mitreKillChains = attck.mitre_kill_chain;
  const references = getValues(attck.raw_intel).length;

  mitreKillChains.forEach((chain) => {
    if (!accu[chain]) {
      accu[chain] = [];
    }

    accu[chain].push({
      name: attck.id_external,
      id: attck.id,
      mitre_name: attck.name,
      taxonomy: attck.taxonomy,
      platforms: attck.platforms,
      mitre_kill_chain: attck.mitre_kill_chain,
      subtechniques_id: getKeys(attck.children).map((sbt) => `${sbt}`),
      issues_appearances: references,
    });
  });

  return accu;
}, {} as Overviews);
