import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openapiClient, safePromise } from '@/lib/openapi-fetch';

import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';
import { unifiedApiKeys } from '@/services/keys';
import { providersKeys } from './keys';

import { isNotNull, mergeUniqueArray, safeMergeArrays } from '@/utils';

import { INTEL_ENDPOINTS } from '@/client/features/intelligence/services/keys';
import { Schema, UnifiedApiAggResponse, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import { combineAggEntries, normalizeAggEntry } from '@/components/content/chart/utils/transformations';
import { FetchError } from '@/types/api';
import { NonUndefined } from '@/types/general';

function useProviders() {
  const service = useCreateUnifiedApiService('/catalog/providers/', openapiClient);

  return {
    list: service.list,
  };
}

function providersResourceFetchFn(
  id: number,
) {
  return openapiClient.GET(
    '/catalog/providers/{resource_id}',
    {
      params: {
        path: { resource_id: id },
      },
      headers: { source: 'entity' },
    },
  );
}

async function providersEntityFetchFn(id: number) {
  const promises = await Promise.all([
    safePromise(providersResourceFetchFn(id)),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.finished,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `providers.id:${id}`,
              'populate_catalog:true',
            ],
            agg: [
              // Relationships
              'threat_actor_intent',
              'threat_actor_capability',
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'locations',
              'iocs',
            ],
          },
        },
      },
    )),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.raw,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `providers.id:${id}`,
            ],
            agg: [
              // Relationships
              'threat_actor_motivation',
              'threat_actor_intent',
              'threat_actor_capability',
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'locations',
              'iocs',
            ],
          },
        },
      },
    )),
  ]);

  if (promises[0].error) {
    throw new Error(promises[0].error);
  }

  if (promises.every((p) => isNotNull(p.error))) {
    throw new Error('Failed to fetch providers entity');
  }

  return promises;
}

type ProvidersContext = {
  threat_actor_motivation: ReturnType<typeof normalizeAggEntry>;
  threat_actor_intent: ReturnType<typeof normalizeAggEntry>;
  threat_actor_capability: ReturnType<typeof normalizeAggEntry>;
  threat_actors: ReturnType<typeof normalizeAggEntry>;
  relevant_malware_kits: ReturnType<typeof normalizeAggEntry>;
  relevant_industries: ReturnType<typeof normalizeAggEntry>;
  relevant_technologies: ReturnType<typeof normalizeAggEntry>;
  relevant_locations: ReturnType<typeof normalizeAggEntry>;
  iocs: Array<string>;
};

export type ProvidersResourceFetchResponse = NonUndefined<
Awaited<ReturnType<typeof providersResourceFetchFn>>['data']
>;

export type ProvidersEntity = ProvidersResourceFetchResponse & ProvidersContext;

export function providersEntityQuery(
  id: number,
  enabled?: boolean,
): UseQueryOptions<Awaited<ReturnType<typeof providersEntityFetchFn>>, FetchError, ProvidersEntity> {
  return {
    queryKey: unifiedApiKeys.ticket('/catalog/providers', id),
    queryFn: () => providersEntityFetchFn(id),
    select: (data) => {
      const [
        entity,
        finished,
        raw,
      ] = data;

      const finishedAggs = finished.data?.data as UnifiedApiAggResponse;
      const rawAggs = raw.data?.data as UnifiedApiAggResponse;

      const [
        threatActorIntent,
        threatActorCapability,
        threatActors,
        malwareKits,
        industries,
        technologies,
        locations,
        iocs,
      ] = finishedAggs.aggs;

      const [
        threatActorMotivationRaw,
        threatActorIntentRaw,
        threatActorCapabilityRaw,
        threatActorsRaw,
        malwareKitsRaw,
        industriesRaw,
        technologiesRaw,
        locationsRaw,
        iocsRaw,
      ] = rawAggs.aggs;

      const contextData = {
        threat_actor_motivation: normalizeAggEntry(threatActorMotivationRaw ?? []),
        threat_actor_intent: normalizeAggEntry(combineAggEntries(threatActorIntent, threatActorIntentRaw)),
        threat_actor_capability: normalizeAggEntry(combineAggEntries(threatActorCapability, threatActorCapabilityRaw)),
        threat_actors: normalizeAggEntry(combineAggEntries(threatActors, threatActorsRaw)),
        relevant_malware_kits: normalizeAggEntry(combineAggEntries(malwareKits, malwareKitsRaw)),
        relevant_industries: normalizeAggEntry(combineAggEntries(industries, industriesRaw)),
        relevant_technologies: normalizeAggEntry(combineAggEntries(technologies, technologiesRaw)),
        relevant_locations: normalizeAggEntry(combineAggEntries(locations, locationsRaw)),
        iocs: safeMergeArrays(iocs, iocsRaw).map((ioc) => ioc.key[0]),
      };

      return {
        ...(entity.data?.data as NonUndefined<ProvidersResourceFetchResponse>),
        ...contextData,
      };
    },
    enabled,
  };
}

export function useProvidersEntity(
  id: number,
  enabled?: boolean,
) {
  return useQuery(providersEntityQuery(id, enabled));
}

export function useProvidersTable(
  {
    filter,
    ...params
  }: Partial<UnifiedApiParams>,
) {
  return useProviders().list({
    queryKey: providersKeys.table,
    select: (raw) => {
      const getFromRawIntel = (record: Schema<'ProviderResponse'>) => (
        Array.isArray(record.raw_intel) ? record.raw_intel.reduce((accu, curr) => {
          const {
            industries,
            threat_actor_capability: threatActorCapability,
            threat_actor_intent: threatActorIntent,
            threat_actor_motivation: threatActorMotivation,
          } = curr;

          accu.industries = { ...accu.industries, ...industries as Record<string, string> };
          accu.threat_actor_capability = mergeUniqueArray(accu.threat_actor_capability, threatActorCapability);
          accu.threat_actor_intent = mergeUniqueArray(accu.threat_actor_intent, threatActorIntent);
          accu.threat_actor_motivation = mergeUniqueArray(accu.threat_actor_motivation, threatActorMotivation);

          return accu;
        }, {
          industries: {},
          threat_actor_capability: [],
          threat_actor_intent: [],
          threat_actor_motivation: [],
        } as Record<
        'threat_actor_capability' | 'threat_actor_intent' | 'threat_actor_motivation',
        Array<string>
        >
        & Record<'industries', Record<string, string>>) : {});

      const data = {
        ...raw.data,
        records: raw.data?.records.map((record) => ({
          ...record,
          ...getFromRawIntel(record),
        })),
      };

      return data;
    },
  }, {
    params: {
      query: {
        filter: [
          'raw_intel:*',
          'description:*',
          ...(filter ?? []),
        ],
        include: [
          'raw_intel',
        ],
        ...params,
      },
    },
    headers: { source: 'table' },
  });
}
