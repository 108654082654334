import { useMemo } from 'react';
import { keepPreviousData, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';
import { useRawIntel } from '@/client/features/intelligence/services/getRawIntel';
import { useFinishedIntel } from '@/client/features/intelligence/services/getFinishedIntel';
import { useAuth } from '@/hooks/useAuth';
import { openapiClient, safePromise } from '@/lib/openapi-fetch';

import { INTEL_ENDPOINTS } from '@/client/features/intelligence/services/keys';
import { unifiedApiKeys } from '@/services/keys';
import {
  combineAggEntries,
  fillMissingDates,
  normalizeAggEntry,
  unifiedApiAggToTimelineOption,
  unifiedApiToGradientOption,
} from '@/components/content/chart/utils/transformations';
import { isNotNull } from '@/utils';
import { attcksKeys } from './keys';

import type { UnifiedApiAggResponse, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { FetchError } from '@/types/api';
import type { NonUndefined } from '@/types/general';

export const useAttcks = () => {
  const service = useCreateUnifiedApiService('/catalog/attcks/', openapiClient);

  return {
    list: service.list,
    summary: service.summary,
  };
};

function attcksResourceFetchFn(
  id: number,
) {
  return openapiClient.GET(
    '/catalog/attcks/{resource_id}',
    {
      params: {
        path: { resource_id: id },
      },
      headers: { source: 'entity' },
    },
  );
}

async function attcksEntityFetchFn(
  id: number,
) {
  const promises = await Promise.all([
    safePromise(attcksResourceFetchFn(id)),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.finished,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `attcks.id:${id}`,
              'populate_catalog:true',
            ],
            agg: [
              // Relationships
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'attack_vector',
              'locations',

              // GFX
              'threat_actors limit:5',
              'malware_kits limit:5',
            ],
          },
        },
      },
    )),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.raw,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `attcks.id:${id}`,
            ],
            agg: [
              // Relationships
              'threat_actors',
              'malware_kits',
              'industries',
              'technologies',
              'locations',
              'attack_vector',

              // GFX
              'threat_actors limit:5',
              'malware_kits limit:5',
            ],
          },
        },
      },
    )),
  ]);

  if (promises[0].error) {
    throw new Error(promises[0].error);
  }

  if (promises.every((p) => isNotNull(p.error))) {
    throw new Error('Failed to fetch attcks entity');
  }

  return promises;
}

type AttcksContext = {
  relevant_industries: ReturnType<typeof normalizeAggEntry>;
  relevant_technologies: ReturnType<typeof normalizeAggEntry>;
  relevant_locations: ReturnType<typeof normalizeAggEntry>;
  threat_actors: ReturnType<typeof normalizeAggEntry>;
  malware_kits: ReturnType<typeof normalizeAggEntry>;
  attack_vector: ReturnType<typeof normalizeAggEntry>;
  top5ThreatActors: ReturnType<typeof unifiedApiToGradientOption>;
  top5MalwareKits: ReturnType<typeof unifiedApiToGradientOption>;
};

export type AttcksResourceFetchResponse = NonUndefined<Awaited<ReturnType<typeof attcksResourceFetchFn>>['data']>;

export type AttcksEntity = AttcksResourceFetchResponse & AttcksContext;

export function attcksEntityQuery(
  id: number,
  enabled?: boolean,
): UseQueryOptions<Awaited<ReturnType<typeof attcksEntityFetchFn>>, FetchError, AttcksEntity> {
  return {
    queryKey: unifiedApiKeys.ticket('/catalog/attcks/', id),
    queryFn: () => attcksEntityFetchFn(id),
    select: (data) => {
      const [
        entity,
        finished,
        raw,
      ] = data;

      const finishedAggs = finished.data?.data as UnifiedApiAggResponse;
      const rawAggs = raw.data?.data as UnifiedApiAggResponse;

      const [
        threatActors,
        malwareKits,
        industries,
        technologies,
        locations,
        attackVector,

        // GFX
        top5ThreatActors,
        top5MalwareKits,
      ] = finishedAggs.aggs;

      const [
        threatActorsRaw,
        malwareKitsRaw,
        industriesRaw,
        technologiesRaw,
        locationsRaw,
        attackVectorRaw,

        // GFX
        top5ThreatActorsRaw,
        top5MalwareKitsRaw,
      ] = rawAggs.aggs;

      const contextData = {
        threat_actors: normalizeAggEntry(combineAggEntries(threatActors, threatActorsRaw)),
        malware_kits: normalizeAggEntry(combineAggEntries(malwareKits, malwareKitsRaw)),
        relevant_industries: normalizeAggEntry(combineAggEntries(industries, industriesRaw)),
        relevant_technologies: normalizeAggEntry(combineAggEntries(technologies, technologiesRaw)),
        relevant_locations: normalizeAggEntry(combineAggEntries(locations, locationsRaw)),
        attack_vector: normalizeAggEntry(combineAggEntries(attackVector, attackVectorRaw)),

        // GFX
        top5ThreatActors: unifiedApiToGradientOption(combineAggEntries(top5ThreatActors, top5ThreatActorsRaw)),
        top5MalwareKits: unifiedApiToGradientOption(combineAggEntries(top5MalwareKits, top5MalwareKitsRaw)),
      };

      return {
        ...(entity.data?.data as NonUndefined<AttcksResourceFetchResponse>),
        ...contextData,
      };
    },
    enabled,
  };
}

export function useAttcksEntity(
  id: number,
  enabled?: boolean,
) {
  return useQuery(attcksEntityQuery(id, enabled));
}

export function useAttcksTable({
  filter = [],
  ...params
}: Partial<UnifiedApiParams> = {}) {
  const auth = useAuth();

  return useAttcks().list({
    placeholderData: keepPreviousData,
    queryKey: attcksKeys.table,
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        ...params,
        include: [
          'children',
        ],
        filter: [
          'raw_intel:*',
          'NOT parents:*',
          ...filter,
        ],
      },
    },
    headers: { source: 'table' },
  });
}

export function useAttcksGFX(filter: UnifiedApiParams['filter']) {
  const auth = useAuth();

  const attcksRootQuery = useAttcks().list(
    {
      queryKey: () => ['/catalog/attcks/', 'root'],
      select: (data) => {
        const attcks = data.data as UnifiedApiAggResponse;

        return attcks.aggs[0].flatMap((agg) => agg.key);
      },
    },
    {
      params: {
        query: {
          length: 0,
          filter: ['NOT parents:*'],
          agg: [
            'id',
          ],
        },
      },
    },
  );

  const attcksSubtechniquesQuery = useAttcks().list(
    {
      queryKey: () => ['/catalog/attcks/', 'subtechiniques'],
      select: (data) => {
        const attcks = data.data as UnifiedApiAggResponse;

        return attcks.aggs[0].flatMap((agg) => agg.key);
      },
    },
    {
      params: {
        query: {
          length: 0,
          filter: ['parents:*'],
          agg: [
            'id',
          ],
        },
      },
    },
  );

  const finishedIntel = useFinishedIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => attcksKeys.gfx(INTEL_ENDPOINTS.finished, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        attcks,
        attcksTimeline,
      ] = data.aggs;

      return {
        attcks,
        attcksTimeline,
      };
    },
    enabled: auth.userInfoQuery.isSuccess && attcksRootQuery.isSuccess && attcksSubtechniquesQuery.isSuccess,
  }, {
    params: {
      query: {
        filter: [
          'attcks:{name:*}',
          'populate_catalog:true',
          ...(filter ?? []),
        ],
        agg: [
          'attcks order:desc',
          'month(published_at) attcks order:asc',
        ],
      },
    },
  });

  const rawIntel = useRawIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => attcksKeys.gfx(INTEL_ENDPOINTS.raw, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        attcks,
        attcksTimeline,
      ] = data.aggs;

      return {
        attcks,
        attcksTimeline,
      };
    },
    enabled: auth.userInfoQuery.isSuccess && attcksRootQuery.isSuccess && attcksSubtechniquesQuery.isSuccess,
  }, {
    params: {
      query: {
        length: 0,
        filter: [
          'attcks:{name:*}',
          'status:published',
          ...(filter ?? []),
        ],
        agg: [
          'attcks',
          'month(published_at) attcks order:asc',
        ],
      },
    },
  });

  const data = useMemo(() => {
    if (!finishedIntel.data
      || !rawIntel.data
      || !attcksRootQuery.data
      || !attcksSubtechniquesQuery.data
    ) return undefined;

    const { attcks, attcksTimeline } = finishedIntel.data;
    const { attcks: attcksRaw, attcksTimeline: attcksTimelineRaw } = rawIntel.data;

    const attcksRoot = attcksRootQuery.data;
    const attcksSubtechniques = attcksSubtechniquesQuery.data;

    const attcksCombined = combineAggEntries(attcks, attcksRaw).sort((a, b) => b.count - a.count);
    const attcksTimelineCombined = combineAggEntries(attcksTimeline, attcksTimelineRaw);

    const relatedRootAttcks = attcksCombined.filter((item) => attcksRoot.includes(item.key[0]));
    const relatedSubtechniques = attcksCombined.filter((item) => attcksSubtechniques.includes(item.key[0]));

    const top5Attcks = unifiedApiToGradientOption(relatedRootAttcks.slice(0, 5));
    const top5Subtechniques = unifiedApiToGradientOption(relatedSubtechniques.slice(0, 5));

    const topAttcks5Set = new Set(top5Attcks.dimensions);
    const topSubtechniques5Set = new Set(top5Subtechniques.dimensions);

    const relatedRootAttcksTimeline = attcksTimelineCombined.filter((item) => (
      attcksRoot.includes(item.key[1]) && topAttcks5Set.has(item.key[2])
    ));

    const relatedSubtechniquesTimeline = attcksTimelineCombined.filter((item) => (
      attcksSubtechniques.includes(item.key[1]) && topSubtechniques5Set.has(item.key[2])
    ));

    return {
      top5Attcks,
      top5Subtechniques,
      attcksTimelineOption: unifiedApiAggToTimelineOption(fillMissingDates(relatedRootAttcksTimeline)),
      subtechniquesTimelineOption: unifiedApiAggToTimelineOption(fillMissingDates(relatedSubtechniquesTimeline)),
    };
  }, [
    finishedIntel.data,
    rawIntel.data,
  ]);

  return {
    isPending: finishedIntel.isPending || rawIntel.isPending,
    isError: finishedIntel.isError || rawIntel.isError,
    isFetching: finishedIntel.isFetching || rawIntel.isFetching,
    error: finishedIntel.error || rawIntel.error,
    data,
  };
}

export function useAttcksOverview(
  filter: UnifiedApiParams['filter'],
) {
  const auth = useAuth();

  return useAttcks().summary({
    queryKey: attcksKeys.overview,
    select: (raw) => {
      const data = raw.data as any;

      return data.overview;
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        filter: [
          ...(filter ?? []),
        ],
      },
    },
  });
}
