import { unifiedApiKeys } from '@/services/keys';

import type { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';

export const attcksKeys = {
  table: (params?: Partial<UnifiedApiParams>) => (
    ['table', ...unifiedApiKeys.records('/catalog/attcks/', params)]
  ) as const,

  gfx: (context: string, params?: Partial<UnifiedApiParams>) => (
    ['attcks', 'gfx', ...unifiedApiKeys.records(context, params)]
  ) as const,

  overview: (context: string, params?: Partial<UnifiedApiParams>) => (
    ['attcks', 'overview', ...unifiedApiKeys.records(context, params)]
  ) as const,
};
