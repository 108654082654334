import { Flex } from '@mantine/core';

import Resolve from '../../clientstate/components/Resolve';
import RequestTakedown from './RequestTakedown';
import PrintButton from '@/components/content/button/PrintButton';
import Acknowledge from '../../clientstate/components/Acknowledge';

import { useIssueState } from '../hooks/useIssueState';
import { incidentsKeys, INCIDENTS_ENDPOINTS } from '../services/keys';
import { unifiedApiKeys } from '@/services/keys';
import { notificationsKeys } from '../../notifications/services/keys';

import { OIncidentsStatus } from '../types';
import type { Schema } from '@/types/mercury-data-types/unifiedapi';
import { MercuryTrackersIds } from '@/constants/mercuryPages';

interface IncidentActionsProps {
  issueId: number;
  clientIds: Array<string>;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
  print?: boolean;
  name?: string;
  clientState?: Schema<'IssueClientResponse'>;
  hasTakedownRequests?: boolean;
}

const IncidentActions = ({
  asButton,
  issueId,
  loading,
  disabled,
  print,
  name,
  clientState,
  clientIds,
  hasTakedownRequests,
}: IncidentActionsProps) => {
  const [status, previousStatus] = useIssueState(clientState, hasTakedownRequests);
  const clientStateBody = [[issueId, clientIds]] as Array<[number, Array<string>]>;

  return (
    <Flex
      align="center"
      justify="center"
      gap={10}
      flex="1"
      direction={{
        base: asButton ? 'column' : 'row',
        s: 'row',
      }}
    >
      {print && (
        <PrintButton documentTitle={['QuoIntelligence', `${issueId}`, name || '']} asButton />
      )}

      {status?.toLowerCase() !== OIncidentsStatus.Pending && status?.toLowerCase() !== OIncidentsStatus.Resolved && (
        <Acknowledge
          id={clientStateBody}
          isAcknowledged={status === OIncidentsStatus.Acknowledged}
          resource={INCIDENTS_ENDPOINTS.incidents}
          invalidate={[
            notificationsKeys.notificationNew(MercuryTrackersIds.alerts),
            unifiedApiKeys.ticket(INCIDENTS_ENDPOINTS.incidents, issueId),
            incidentsKeys.incidentsGfx(),
            incidentsKeys.incidentsTable(),
            incidentsKeys.incidentsDashboard(),
          ]}
          isLoading={loading}
          disabled={disabled}
          asButton={asButton}
        />
      )}

      <Resolve
        id={clientStateBody}
        status={status}
        resource={INCIDENTS_ENDPOINTS.incidents}
        previousStatus={previousStatus}
        invalidate={[
          unifiedApiKeys.ticket(INCIDENTS_ENDPOINTS.incidents, issueId),
          notificationsKeys.notificationNew(MercuryTrackersIds.alerts),
          incidentsKeys.incidentsGfx(),
          incidentsKeys.incidentsTable(),
          incidentsKeys.incidentsDashboard(),
        ]}
        disabled={disabled}
        asButton={asButton}
      />

      <RequestTakedown
        issueId={issueId}
        disabled={disabled}
        loading={loading}
        asButton={asButton}
      />
    </Flex>
  );
};

export default IncidentActions;
