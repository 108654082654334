import type { UnifiedApiAggs } from '@/types/mercury-data-types/unifiedapi';
import { getEntries, getKeys, isNotNull } from '@/utils';

export function objectToNameValueOption(
  data: Record<string, number>,
  mapName?: (name: string) => string,
) {
  return {
    dimensions: getKeys(data),
    source: getEntries(data).map(([key, value]) => ({
      value,
      name: mapName ? mapName(key) : key,
    })),
  };
}

export function unifiedApiAggToDonutOption(
  data: UnifiedApiAggs = [],
  { mapName, attrName }: { mapName?: (name: string) => string; attrName?: string } = {},
) {
  const filteredData = data.filter((d) => d.key.some(isNotNull));

  const dimensions = Array.from(
    new Set(
      filteredData.map(({ key }) => key[1] || key[0]),
    ),
  );

  return {
    dimensions,
    source: filteredData.map(({ key, count }) => (
      { [attrName ?? 'name']: mapName ? mapName(key[1] || key[0]) : (key[1] || key[0]), value: count })),
  };
}

export function unifiedApiAggToTimelineOption(
  data: UnifiedApiAggs,
  { mapName }: { mapName?: (name: string) => string } = {},
) {
  const { dimensions, monthData } = data
    .filter((d) => d.key.some(isNotNull))
    .reduce(
      (acc, { key, count }) => {
        const [date, id, name] = key;
        const dataPoint = mapName ? mapName(name || id) : name || id;
        // Add dataPoint to dimensions
        acc.dimensions.add(dataPoint);

        // Get or initialize month data
        if (!acc.monthData.has(date)) {
          acc.monthData.set(date, { date });
        }

        // Add count to month data
        const monthRecord = acc.monthData.get(date)!;
        monthRecord[dataPoint] = count;

        return acc;
      },
      {
        dimensions: new Set<string>(),
        monthData: new Map(),
      },
    );

  return {
    dimensions: Array.from(dimensions),
    source: Array.from(monthData.values()),
  };
}

export function unifiedApiToGradientOption(data: UnifiedApiAggs) {
  return data
    .filter((d) => d.key.some(isNotNull))
    .sort((a, b) => b.count - a.count)
    .reduce((accu, curr) => {
      accu.dimensions.push(curr.key[1] || curr.key[0]);
      accu.source.push(curr.count);

      return accu;
    }, { dimensions: [] as Array<string>, source: [] as Array<number> });
}

export function fillMissingDates(data: UnifiedApiAggs) {
  // Get all unique dates from the input
  const allDates = [...new Set(data.map((item) => item.key[0]))];

  // Get unique ID-item pairs using a Set with a simple string key
  const idItemPairs = new Set(
    data.map((item) => `${item.key[1]}-${item.key[2]}`),
  );

  const existingMap = new Map(
    data.map((item) => [item.key.join('-'), item.count]),
  );

  const result: UnifiedApiAggs = [];

  // For each ID-item pair
  idItemPairs.forEach((pairKey) => {
    const [id, item] = pairKey.split('-');

    // Generate an entry for each date
    allDates.forEach((date) => {
      const key: Array<string> = [date, id, item];
      result.push({
        key,
        count: existingMap.get(key.join('-')) || 0,
      });
    });
  });

  return result;
}

export function normalizeAggEntry(
  data: UnifiedApiAggs = [],
) {
  return data
    .filter((el) => isNotNull(el.key[0]))
    .map((el) => {
      const id = el.key[0];
      const name = el.key[1];

      return {
        id,
        name: name || id,
        amount: el.count,
      };
    });
}

export function combineAggEntries(
  data: UnifiedApiAggs = [],
  data2: UnifiedApiAggs = [],
) {
  const resultMap = new Map<string, { originalKey: Array<string>, count: number }>();

  data.forEach((el) => {
    const keyString = JSON.stringify(el.key);
    resultMap.set(keyString, { originalKey: el.key, count: el.count });
  });

  data2.forEach((el) => {
    const keyString = JSON.stringify(el.key);

    if (resultMap.has(keyString)) {
      const existing = resultMap.get(keyString)!;
      existing.count += el.count;
    } else {
      resultMap.set(keyString, { originalKey: el.key, count: el.count });
    }
  });

  return Array.from(resultMap.values()).map(({ originalKey, count }) => ({
    key: originalKey,
    count,
  }));
}
