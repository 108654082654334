import { Center } from '@mantine/core';

import Linkv2 from '@/components/content/link/Linkv2';

import { useNotificationsMarkAllAsReadMutation } from '../services/markAllAsRead';
import { useToast } from '@/hooks/useToast';

const NotificationsReadAll = () => {
  const { mutate: markAllAsReadMutation, isPending } = useNotificationsMarkAllAsReadMutation();
  const { createToast } = useToast();

  const onClick = () => {
    markAllAsReadMutation({}, {
      onError: (error) => {
        createToast(error.message, 'error');
      },
    });
  };

  return (
    <Center mt={15}>
      <Linkv2 as="button" type="button" onClick={onClick} animate={isPending} disabled={isPending}>
        Mark all as read
      </Linkv2>
    </Center>
  );
};

export default NotificationsReadAll;
