import { Stack, Text } from '@mantine/core';
import Markdown from '@/components/content/markdown/Markdown';

import RiskIndicators from '../../../../riskindicators/RiskIndicators';

import { createHelper } from '@/client/features/issue/utils/helper';
import { PersonaEntity } from '../services/personas';

const helper = createHelper<PersonaEntity>();

export const personasDrawer = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Persona</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: data.relevant_locations,
        technologies: data.relevant_technologies,
        industry_sectors: data.relevant_industries,
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} />,
        scroll: false,
      }),
    ],
  },
];
