type Env = Window['env'];

const getMercuryEnv = <K extends keyof Env>(variable: K): Env[K] => window.env[variable];

export const ADMIN_URL = getMercuryEnv('REACT_APP_MERCURY_ADMIN_URL');
export const MERCURY_URL = getMercuryEnv('REACT_APP_MERCURY_URL');
export const MERCURY_ENV = getMercuryEnv('REACT_APP_MERCURY_ENVIRONMENT');
export const OPS_BASE_URL = getMercuryEnv('REACT_APP_MERCURY_OPS_BASE_URL');
export const DISPLAY_ENV_WARNING = getMercuryEnv('REACT_APP_MERCURY_DISPLAY_ENV_WARNING');
export const SENTRY_DSN = getMercuryEnv('REACT_APP_SENTRY_DSN');
