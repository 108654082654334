import type { PropsWithChildren } from 'react';
import { Tooltip } from '@mantine/core';

import DrawerLink from '../drawerlink/DrawerLink';
import SupportForm from '../mailboxes/SupportForm';

import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';

import { SUPPORT_SUBJECTS, SUPPORT_TYPES } from '../mailboxes/types';
import { TAccessModules } from '@/constants/permissions';

interface BlockedLinkProps {
  modules: readonly TAccessModules[];
  id: number;
  name: string;
}

const BlockedLink = ({
  modules,
  id,
  name,
  children,
}: PropsWithChildren<BlockedLinkProps>) => {
  const { hasAccess } = useMercuryPermissions();
  const canAccess = hasAccess({ modules });

  if (!canAccess) {
    return (
      <Tooltip label="You need a premium subscription to see this content">
        <DrawerLink
          id={id}
          title="Request access to premium module"
          value={name}
          content={(
            <SupportForm
              disabledFields={{ subject: true }}
              name="support-form"
              prefillData={{
                subject: SUPPORT_SUBJECTS.PREMIUM_TRIAL,
                description: 'I would like to request access to the premium module',
                to: SUPPORT_TYPES.SUPPORT,
              }}
              subjects={[SUPPORT_SUBJECTS.PREMIUM_TRIAL]}
            />
          )}
        />
      </Tooltip>
    );
  }

  return children;
};

export default BlockedLink;
