import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType } from 'react-router-dom';
import { MERCURY_ENV, SENTRY_DSN } from '@/constants/env';

Sentry.init({
  dsn: SENTRY_DSN,
  release: process.env.MERCURY_VERSION,
  environment: MERCURY_ENV,
  tunnel: '/api/sentry-tunnel',
  debug: MERCURY_ENV === 'STAGING',
  enabled: MERCURY_ENV !== 'DEV' && MERCURY_ENV !== 'TEST',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,
});
