import { postMutation } from '@/lib/react-query/mutate';

const login = 'login';

export const useAuthMutation = () => postMutation({
  endpoint: login,
  requestOptions: {
    unauthorized: 'Incorrect username or password.',
  },
  mutationOptions: {
    meta: {
      avoidAuthRedirection: true,
    },
  },
});
