import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Client,
  FetchResponse,
  InitParam,
  MaybeOptionalInit } from 'openapi-fetch';
import { PathsWithMethod } from 'openapi-typescript-helpers';
import { unifiedApiKeys } from '@/services/keys';
import { FetchError } from '@/types/api';

// Helper type to construct the item path
type ItemPath<T extends string> = T extends `${infer Base}/`
  ? `${Base}/{resource_id}`
  : `${T}/{resource_id}`;

type SummaryPath<T extends string> = T extends `${infer Base}/`
  ? `${Base}/summary`
  : `${T}/summary`;

export function useCreateUnifiedApiService<
  Paths extends Record<string, any>,
  T extends string & PathsWithMethod<Paths, 'get'>,
  Init extends MaybeOptionalInit<Paths[T], 'get'>,
  ItemT extends ItemPath<T> = ItemPath<T>,
  SummaryT extends SummaryPath<T> = SummaryPath<T>,
>(
  basePath: T,
  client: Client<Paths>,
) {
  const list = <TResult = FetchResponse<Paths[T]['get'], Init, `${string}/${string}`>>(
    {
      queryKey,
      ...options
    }: {
      queryKey?: (params: any) => QueryKey,
    } & Omit<UseQueryOptions<
    FetchResponse<Paths[T]['get'], Init, `${string}/${string}`>,
    FetchError,
    TResult
    >, 'queryKey' | 'queryFn'>,
    ...init: InitParam<MaybeOptionalInit<Paths[T], 'get'>>
  ) => useQuery({
      queryKey: queryKey?.(init) ?? unifiedApiKeys.records(basePath, init),
      queryFn: () => client.GET(basePath, ...init),
      ...options,
    });

  const ticket = <TResult = FetchResponse<Paths[ItemT]['get'], Init, `${string}/${string}`>>(
    id: number,
    {
      select,
      queryKey,
    }: {
      select?: (data: FetchResponse<Paths[ItemT]['get'], Init, `${string}/${string}`>) => TResult,
      queryKey?: (params: any) => QueryKey,
    },
    ...init: InitParam<MaybeOptionalInit<Paths[ItemT], 'get'>>
  ) => useQuery({
      queryKey: queryKey?.(init) ?? unifiedApiKeys.ticket(basePath, id),
      queryFn: () => client.GET(`${basePath}{resource_id}` as any, ...init),
      select,
    });

  const summary = <TResult = FetchResponse<Paths[SummaryT]['get'], Init, `${string}/${string}`>>(
    {
      queryKey,
      ...options
    }: {
      queryKey?: (params: any) => QueryKey,
    } & Omit<UseQueryOptions<
    FetchResponse<Paths[T]['get'], Init, `${string}/${string}`>,
    FetchError,
    TResult
    >, 'queryKey' | 'queryFn'>,
    ...init: InitParam<MaybeOptionalInit<Paths[SummaryT], 'get'>>
  ) => useQuery({
      queryKey: queryKey?.(init) ?? unifiedApiKeys.summary(basePath, init),
      queryFn: () => client.GET(`${basePath}summary` as any, ...init),
      ...options,
    });

  return {
    list,
    ticket,
    summary,
  };
}
