import { useGetPreCheck } from '../services/precheck';
import { useQueryActions } from '@/hooks/useQueryActions';
import { authKeys } from '../services/keys';

import type { PrecheckReturn } from '../types';

export const useAuthType = (avoidPrecheck: boolean) => {
  const { setData } = useQueryActions();
  const {
    data: precheck,
    isLoading:
    isGettingType,
    isError: noPrecheckData,
  } = useGetPreCheck(!avoidPrecheck);

  const isUnauthorized = !precheck && !isGettingType && noPrecheckData;

  const setPrecheckData = (data: PrecheckReturn | null) => {
    setData<PrecheckReturn | null>(authKeys.precheck(), () => data);
  };

  return {
    precheck,
    isUnauthorized,
    isGettingType,
    setPrecheckData,
  };
};
