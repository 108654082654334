import type { ReactNode } from 'react';
import type { Permissions } from '@/types/permissions';
import type { FlexProps } from '@mantine/core';

export type Accessor<T, R> = ((data: T) => R);

export type SubmoduleDef<TData, TValue = any> = Submodule<TValue> & {
  accessor: Accessor<TData, TValue>;
  render: string | number | true | Iterable<ReactNode> | JSX.Element;
};

export interface ModuleDef<TData, TValue = any> extends Module<TData, TValue> {
  submodules: Array<SubmoduleDef<TData, TValue>>;
}

export const ModuleLayouts = {
  LIST: 'list',
  GRID: 'grid',
  FLEX: 'flex',
  TABS: 'tabs',
} as const;

type ModuleLayout = typeof ModuleLayouts[keyof typeof ModuleLayouts];

export const ModuleLocations = {
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
} as const;

export type ModuleLocation = typeof ModuleLocations[keyof typeof ModuleLocations];

export const AUTOORDER = 'auto' as const;

type Order = { position: number | typeof AUTOORDER; location: ModuleLocation };

export interface Module<TData, TValue = any> extends FlexProps {
  title?: string;
  layout?: ModuleLayout;
  id?: string;
  submodules: Array<Submodule<TValue> & { accessor: Accessor<TData, TValue>; }>;
  removeBackground?: boolean;
  removePadding?: boolean;
  removeMargin?: boolean;
  hide?: boolean;
  order?: { baseline?: Order, md?: Order };
  icon?: ReactNode;
  noPrint?: boolean;
}

type NonNullable<T> = T extends null ? never : T;

export type ElementProps<R> = {
  getValue: () => NonNullable<R>;
  id: string | number;
  loading: boolean;
};

export interface Submodule<R> extends FlexProps {
  title?: string;
  element?: (props: ElementProps<R>) => ReactNode;
  layout?: ModuleLayout;
  scroll?: boolean;
  canCopy?: boolean;
  canEdit?: Permissions;
  allowInvalidValue?: string;
  hide?: boolean;
}
