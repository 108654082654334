export const formatDateString = (value: string | Date | null, options?: Intl.DateTimeFormatOptions) => (
  value ? new Date(value).toLocaleDateString('en-GB', { timeZone: 'UTC', ...options }) : ''
);

export const formatIsoDate = (
  value: string,
  removeTime: boolean = false,
  separator: string = ' ',
) => {
  if (!value) return value;

  const formattedDate = value.slice(0, 19).replace('T', separator);

  return removeTime ? formattedDate.slice(0, 10) : formattedDate;
};
