import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { openapiClient, openApiFetch } from '@/lib/openapi-fetch';
import { unifiedApiKeys } from '@/services/keys';

import { INTEL_ENDPOINTS, intelligenceKeys } from './keys';
import { unifiedApiAggToTimelineOption } from '@/components/content/chart/utils/transformations';
import { getRegions } from '../utils';
import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';

import type { UnifiedApiAggResponse, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { RawIntelTable, RawIntelTableRecord, RawIntelTicket } from '../types';
import { createOverviewAttckObject } from '../../overviewattck/utils/createOverviewObject';
import { useAuth } from '@/hooks/useAuth';

export const rawIssueQuery = (issueId: number, hasPermissions?: boolean) => {
  const baseIncludes = [
    'user_state',
    'finished_intel',
    'incidents',
    'raw_intel',
    'rfis',
    'takedowns',
  ];

  return {
    queryKey: unifiedApiKeys.ticket(INTEL_ENDPOINTS.raw, issueId),
    queryFn: () => openApiFetch(
      `${INTEL_ENDPOINTS.raw}{resource_id}`,
      {
        params: {
          path: { resource_id: issueId },
          query: {
            include: hasPermissions ? [...baseIncludes, 'attcks'] : baseIncludes,
          },
        },
        headers: { source: 'issue' },
      },
    ),
    select: (raw: any) => {
      const data = raw as unknown as Omit<
      RawIntelTicket, 'attachments' | 'attcks_overview'
      >;

      return {
        ...data,
        attcks_overview: createOverviewAttckObject(Array.isArray(data.attcks) ? data.attcks : []),
      };
    },
  };
};

export const useRawIntel = () => {
  const service = useCreateUnifiedApiService(INTEL_ENDPOINTS.raw, openapiClient);

  return {
    list: service.list,
    summary: service.summary,
  };
};

export const useRawIntelTicket = (id: number, hasPermissions?: boolean) => useQuery(rawIssueQuery(id, hasPermissions));

export const rawIntelTableSelect = (record: RawIntelTableRecord) => ({
  ...record,
  regions: getRegions(record.locations),
});

export const useRawIntelTable = (params: Partial<UnifiedApiParams> = {}) => {
  const auth = useAuth();

  return useRawIntel().list({
    placeholderData: keepPreviousData,
    select: (raw) => {
      const data = raw as unknown as RawIntelTable;

      return {
        records: data.data?.records.map(rawIntelTableSelect),
        count: data.data?.count,
      };
    },
    queryKey: intelligenceKeys.rawTable,
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        ...params,
        include: [
          'user_state',
          'locations',
        ],
      },
    },
    headers: { source: 'table' },
  });
};

export const useRawIntelGfx = () => useRawIntel().list({
  select: (raw) => {
    const data = raw.data as unknown as UnifiedApiAggResponse;

    return {
      timeline: unifiedApiAggToTimelineOption(data.aggs[0]),
    };
  },
  queryKey: intelligenceKeys.rawVisualizations,
}, {
  params: {
    query: {
      length: 0,
      filter: [
        'category:*',
        'published_at>now-1y',
      ],
      agg: [
        'month(published_at) category order:asc',
      ],
    },
  },
  headers: { source: 'gfx' },
});
