import { OIncidentsStatus } from '../types';
import type { Schema } from '@/types/mercury-data-types/unifiedapi';

export const useIssueState = (
  clientState?: Schema<'IssueClientResponse'>,
  isPending?: boolean,
): [string, string | undefined] => {
  const status = clientState?.status ? clientState.status : OIncidentsStatus.New;
  const pending = isPending && status !== OIncidentsStatus.Resolved && OIncidentsStatus.Pending;
  const activities = clientState?.activities;
  const previousStatus = activities?.sort((a, b) => (
    new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  ))?.[activities.length - 2]?.type;
  const currentStatus = pending || status;

  return [
    currentStatus,
    previousStatus,
  ];
};
