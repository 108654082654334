import type { Includes } from '@/types/mercury-data-types/metis';
import type { Schema, UnifiedApiFetchResponse, UnifiedApiMethodsResponse } from '@/types/mercury-data-types/unifiedapi';
import type { BaseTicket, BaseTicketIncludes } from '../../issue/types/ticket';
import type { Overwrite } from '@/types/general';

export const OIncidentsStatus = {
  New: 'new',
  Pending: 'pending',
  Acknowledged: 'acknowledged',
  Resolved: 'resolved',
} as const;

export type IncidentStatuses = typeof OIncidentsStatus[keyof typeof OIncidentsStatus];

export type IncidentsListFetchResponse = UnifiedApiFetchResponse<'/issue/incidents/', 'get'>;
export type IncidentsSummaryFetchResponse = UnifiedApiFetchResponse<'/issue/incidents/summary', 'get'>;

/**
 * Overwrite keys that are not included in the request to avoid having to parse them later
 * If a request includes an attribute create a specific type using the Includes<> utility
 */
export type IncidentsTicketResponse = Overwrite<
UnifiedApiMethodsResponse<'/issue/incidents/{resource_id}'>,
{
  clients: { [key: string]: string; };
  takedowns: { [key: string]: string; };
  technologies: { [key: string]: string; };
  sources: { [key: string]: string; };
  vulnerabilities: { [key: string]: string; };
  malware_kits: { [key: string]: string; };
  threat_actors: { [key: string]: string; };
}
>;

interface IncidentsIncludes extends BaseTicketIncludes {
  client_state: Array<Schema<'IssueClientResponse'>>;
}

export type IncidentListRecord = UnifiedApiMethodsResponse<'/issue/incidents/{resource_id}'>;

export type IncidentsTable = Overwrite<IncidentsListFetchResponse,
{ records: Array<IncidentPageTableRecord>; count: number }
>;

export type IncidentPageTableRecord = Includes<
IncidentsTicketResponse,
{
  client_state: Array<Schema<'IssueClientResponse'>>;
  rfis: Array<Schema<'RfiResponse'>>;
}
>;
export type IncidentTicket = BaseTicket<Includes<IncidentsTicketResponse, IncidentsIncludes>>;

export type IncidentSummary = { assignee_count: Array<{ value: number; label: string; count: number }> };
