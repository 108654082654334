import type { QueryKey } from '@tanstack/react-query';

import ClientStateTrigger from './ClientStateTrigger';
import MercuryButton from '@/components/content/button/Button';

import { useQueryActions } from '@/hooks/useQueryActions';

import { OIncidentsStatus } from '../../incidentmanagement/types';

import ResolveIcon from '@/assets/icons/content/resolved.svg';

interface ResolveProps {
  id: Array<[number, Array<string>]>;
  status: string;
  resource: string;
  previousStatus?: string;
  invalidate?: Array<QueryKey>;
  disabled?: boolean;
  isLoading?: boolean;
  asButton?: boolean;
}

const Resolve = ({
  id,
  status,
  previousStatus,
  invalidate,
  resource,
  disabled,
  isLoading,
  asButton,
}: ResolveProps) => {
  const actions = useQueryActions();

  const onSuccess = () => {
    if (invalidate && invalidate.length) {
      // Replication is async and takes some time to complete
      setTimeout(() => {
        actions.invalidateQueries(invalidate, { type: 'all' });
      }, 500);
    }
  };

  const isResolved = status === OIncidentsStatus.Resolved;
  const label = isResolved ? 'Unresolve' : 'Resolve';
  const rollbackStatus = previousStatus === OIncidentsStatus.Acknowledged
    ? OIncidentsStatus.Acknowledged
    : OIncidentsStatus.New;
  const nextStatus = isResolved ? rollbackStatus : OIncidentsStatus.Resolved;

  return asButton ? (
    <ClientStateTrigger
      updateIds={id}
      resource={resource}
      disabled={disabled || isLoading}
      body={{ status: nextStatus }}
      onSuccess={onSuccess}
    >
      <MercuryButton
        size="sm"
        color="var(--quo-blue-75)"
        loading={isLoading}
        rightSection={<ResolveIcon width={20} height={20} />}
      >
        {label}
      </MercuryButton>
    </ClientStateTrigger>
  ) : (
    <ClientStateTrigger
      updateIds={id}
      icon={<ResolveIcon />}
      label={label}
      size={24}
      resource={resource}
      disabled={disabled || isLoading}
      body={{ status: nextStatus }}
      onSuccess={onSuccess}
    />
  );
};

export default Resolve;
