import { QueryKey } from '@tanstack/react-query';
import ClientStateTrigger from './ClientStateTrigger';

import AcknowledgeIcon from '@/assets/icons/content/acknowledge.svg';
import { useQueryActions } from '@/hooks/useQueryActions';
import MercuryButton from '@/components/content/button/Button';
import { OIncidentsStatus } from '../../incidentmanagement/types';

interface AcknowledgeProps {
  id: Array<[number, Array<string>]>;
  isAcknowledged: boolean;
  resource: string;
  invalidate?: Array<QueryKey>;
  disabled?: boolean;
  isLoading?: boolean;
  asButton?: boolean;
}

const Acknowledge = ({
  id,
  isAcknowledged,
  invalidate,
  resource,
  disabled,
  isLoading,
  asButton,
}: AcknowledgeProps) => {
  const actions = useQueryActions();

  const onSuccess = () => {
    if (invalidate && invalidate.length) {
      // Replication is async and takes some time to complete
      setTimeout(() => {
        actions.invalidateQueries(invalidate, { type: 'all' });
      }, 500);
    }
  };

  const label = isAcknowledged ? 'Unacknowledge' : 'Acknowledge';
  const nextStatus = isAcknowledged ? OIncidentsStatus.New : OIncidentsStatus.Acknowledged;

  return asButton ? (
    <ClientStateTrigger
      updateIds={id}
      resource={resource}
      disabled={disabled || isLoading}
      body={{ status: nextStatus }}
      onSuccess={onSuccess}
    >
      <MercuryButton
        color="var(--quo-green)"
        size="sm"
        loading={isLoading}
        rightSection={<AcknowledgeIcon width={15} height={15} />}
      >
        {label}
      </MercuryButton>
    </ClientStateTrigger>
  ) : (
    <ClientStateTrigger
      updateIds={id}
      icon={<AcknowledgeIcon />}
      label={label}
      color={isAcknowledged ? 'var(--quo-green-50)' : ''}
      resource={resource}
      disabled={disabled || isLoading}
      body={{ status: nextStatus }}
      onSuccess={onSuccess}
    />
  );
};

export default Acknowledge;
