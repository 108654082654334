import createClient, { InitParam, MaybeOptionalInit } from 'openapi-fetch';

import { fetchData } from './fetch';
import type { paths } from '../../unifiedapi'; // generated by openapi-typescript
import { UnifiedApiMethodsResponse, UnifiedApiPaths } from '@/types/mercury-data-types/unifiedapi';

export const openapiClient = createClient<paths>({
  baseUrl: '/api/v0',
  fetch: (input) => {
    const request = input.clone();
    const headers = new Headers(request.headers);
    const source = headers.get('source');
    return fetchData({
      endpoint: request.url,
      useCompleteEndpoint: true,
      context: { source },
      options: {
        method: request.method,
        body: request.body,
        headers: request.headers,
        priority: (input as any).priority,
      },
    }, input.signal);
  },
});

export const openApiFetch = async <TPath extends UnifiedApiPaths>(
  path: TPath,
  ...init: InitParam<MaybeOptionalInit<paths[TPath], 'get'>>
) => {
  const response = await openapiClient.GET(path, ...init);

  if (response.error) throw new Error(JSON.stringify(response.error));

  return response.data as UnifiedApiMethodsResponse<TPath>;
};

// Define return type for safe promise
type SafePromiseResult<T> = {
  data: T | null;
  error?: string | null;
};

// Generic helper to wrap promises to never reject
export const safePromise = async <T>(promise: Promise<T>): Promise<SafePromiseResult<T>> => {
  try {
    const result = await promise;
    return { data: result, error: null };
  } catch (error) {
    return { data: null, error: error instanceof Error ? error.message : 'Unknown error' };
  }
};
