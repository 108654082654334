import '../sentry/init';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { RateLimitProvider } from '@/context/RateLimitContext';

import '../styles/globals/fonts.css';
import '../styles/globals/main.css';
import '../mantinecomponents';

// After
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RateLimitProvider>
        <App />
      </RateLimitProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
