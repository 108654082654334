import { Stack, Text } from '@mantine/core';

import PillsList from '@/components/content/pills/PillsList';
import RiskIndicators from '../../../../riskindicators/RiskIndicators';
import Markdown from '@/components/content/markdown/Markdown';
import List from '@/components/content/list/List';
import RedmineFieldList from '../../../components/RedmineFieldList';
import Priority from '@/components/content/priority/Priority';

import { createHelper } from '@/client/features/issue/utils/helper';
import { getContextData } from '../../../utils/context';
import { formatIsoDate } from '@/utils/date';
import { getTaCapabilityAsNumber } from '@/utils/getTaCapabilityAsNumber';

import { getValues } from '@/utils';
import { ThreatActorsEntity } from '../services/threatActors';

const helper = createHelper<ThreatActorsEntity>();

export const threatActorDrawer = [
  {
    submodules: [
      helper.accessor((data) => data.name, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Threat Actor</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: data.relevant_locations,
        technologies: data.relevant_technologies,
        industry_sectors: data.relevant_industries,
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} />,
        scroll: false,
      }),
    ],
  },
  {
    title: 'Threat Information',
    submodules: [
      helper.accessor((data) => data.threat_actor_capability, {
        title: 'Capability',
        element: ({ getValue, id }) => (
          <Priority
            priority={getTaCapabilityAsNumber(getValue().map((c) => c.name))}
            id={`${id}`}
            w="fit-content"
            mt={5}
          />
        ),
      }),
      helper.accessor((data) => data.threat_actor_intent, {
        title: 'Intent',
        element: ({ getValue }) => <RedmineFieldList items={getValue()} />,
      }),
      helper.accessor((data) => data.threat_actor_motivation, {
        title: 'Motivation',
        element: ({ getValue }) => <RedmineFieldList items={getValue()} />,
      }),
      helper.accessor((data) => data.locations, {
        title: 'Sponsoring Country',
        element: ({ getValue }) => getValues(getValue() as {})[0],
      }),
      helper.accessor((data) => data.updated_at, {
        title: 'Last Updated',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.relevant_technologies, {
        title: 'Technologies Affected',
        element: ({ getValue }) => (
          <PillsList
            max={5}
            pills={getValue().map((t) => t.name)}
          />
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => getContextData(data), {
        title: 'Context',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={([name, count]) => `${name} (${count})`}
          />
        ),
        scroll: false,
      }),
    ],
  },
];
