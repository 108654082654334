import { useMemo } from 'react';
import { keepPreviousData, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { openapiClient, safePromise } from '@/lib/openapi-fetch';

import { useFinishedIntel } from '@/client/features/intelligence/services/getFinishedIntel';
import { useRawIntel } from '@/client/features/intelligence/services/getRawIntel';
import { useCreateUnifiedApiService } from '@/lib/useUnifiedApiServiceCreator';
import { createOverviewAttckObject } from '@/client/features/overviewattck/utils/createOverviewObject';
import { useAuth } from '@/hooks/useAuth';

import { isNotNull, mergeUniqueArray, safeMergeArrays } from '@/utils';
import { unifiedApiKeys } from '@/services/keys';
import {
  combineAggEntries,
  fillMissingDates,
  normalizeAggEntry,
  unifiedApiAggToDonutOption,
  unifiedApiAggToTimelineOption,
  unifiedApiToGradientOption,
} from '@/components/content/chart/utils/transformations';
import { getRegions } from '@/client/features/intelligence/utils';
import { threatActorKeys } from './keys';

import { INTEL_ENDPOINTS } from '@/client/features/intelligence/services/keys';
import type { Locations } from '@/types/catalogs';
import type { Schema, UnifiedApiAggResponse, UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { FetchError } from '@/types/api';
import type { NonUndefined } from '@/types/general';

function threatActorsResourceFetchFn(
  id: number,
) {
  return openapiClient.GET(
    '/catalog/threat_actors/{resource_id}',
    {
      params: {
        path: {
          resource_id: id,
        },
        query: {
          include: [
            'raw_intel.attcks',
          ],
        },
      },
      headers: { source: 'entity' },
    },
  );
}

async function threatActorsEntityFetchFn(id: number) {
  const promises = await Promise.all([
    safePromise(threatActorsResourceFetchFn(id)),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.finished,
      {
        params: {
          query: {
            length: 0,
            filter: [
              `threat_actors.id:${id}`,
              'populate_catalog:true',
            ],
            agg: [
              // Relationships
              'threat_actor_intent',
              'threat_actor_capability',
              'attack_vector',
              'providers',
              'personas',
              'vulnerabilities',
              'malware_kits',
              'sources',
              'companies',
              'industries',
              'technologies',
              'locations',
              'iocs',

              // GFX
              'industries limit:5',
              'month(published_at) industries order:asc',
            ],
          },
        },
      },
    )),
    safePromise(openapiClient.GET(
      INTEL_ENDPOINTS.raw,
      {
        params: {
          query: {
            length: 0,
            filter: [`threat_actors.id:${id}`],
            agg: [
              // Relationships
              'threat_actor_motivation',
              'threat_actor_intent',
              'threat_actor_capability',
              'attack_vector',
              'providers',
              'personas',
              'vulnerabilities',
              'malware_kits',
              'sources',
              'companies',
              'industries',
              'technologies',
              'locations',
              'iocs',

              // GFX
              'industries limit:5',
              'month(published_at) industries order:asc',
            ],
          },
        },
      },
    )),
  ]);

  if (promises.every((p) => isNotNull(p.error))) {
    throw new Error('All promises failed');
  }

  return promises;
}

type ThreatActorsContext = {
  threat_actor_motivation: ReturnType<typeof normalizeAggEntry>;
  threat_actor_intent: ReturnType<typeof normalizeAggEntry>;
  threat_actor_capability: ReturnType<typeof normalizeAggEntry>;
  attack_vector: ReturnType<typeof normalizeAggEntry>;
  providers: ReturnType<typeof normalizeAggEntry>;
  personas: ReturnType<typeof normalizeAggEntry>;
  vulnerabilities: ReturnType<typeof normalizeAggEntry>;
  malware_kits: ReturnType<typeof normalizeAggEntry>;
  relevant_sources: ReturnType<typeof normalizeAggEntry>;
  companies: ReturnType<typeof normalizeAggEntry>;
  relevant_industries: ReturnType<typeof normalizeAggEntry>;
  relevant_technologies: ReturnType<typeof normalizeAggEntry>;
  relevant_locations: ReturnType<typeof normalizeAggEntry>;
  attcks_overview: ReturnType<typeof createOverviewAttckObject>;
  industriesTimelineOption: ReturnType<typeof unifiedApiAggToTimelineOption>;
  intentDonut: ReturnType<typeof unifiedApiAggToDonutOption>;
  motivationDonut: ReturnType<typeof unifiedApiAggToDonutOption>;
  locationsMap: ReturnType<typeof unifiedApiAggToDonutOption>;
  iocs: Array<string>;
};

export type ThreatActorsResourceFetchResponse = NonUndefined<
Awaited<ReturnType<typeof threatActorsResourceFetchFn>>['data']
>;

export type ThreatActorsEntity = ThreatActorsResourceFetchResponse & ThreatActorsContext;

export function threatActorsEntityQuery(
  id: number,
  enabled?: boolean,
): UseQueryOptions<Awaited<ReturnType<typeof threatActorsEntityFetchFn>>, FetchError, ThreatActorsEntity> {
  return {
    queryKey: unifiedApiKeys.ticket('/catalog/threat_actors/', id),
    queryFn: () => threatActorsEntityFetchFn(id),
    select: (data) => {
      const [
        entity,
        finishedIntel,
        rawIntel,
      ] = data;

      const finishedAggs = finishedIntel.data?.data as UnifiedApiAggResponse;
      const rawAggs = rawIntel.data?.data as UnifiedApiAggResponse;

      const [
        threatActorIntent,
        threatActorCapability,
        attackVector,
        providers,
        personas,
        vulnerabilities,
        malwareKits,
        sources,
        companies,
        industries,
        technologies,
        locations,
        iocs,

        // GFX
        industriesTop5,
        industriesTimeline,
      ] = finishedAggs.aggs;

      const [
        threatActorMotivationRaw,
        threatActorIntentRaw,
        threatActorCapabilityRaw,
        attackVectorRaw,
        providersRaw,
        personasRaw,
        vulnerabilitiesRaw,
        malwareKitsRaw,
        sourcesRaw,
        companiesRaw,
        industriesRaw,
        technologiesRaw,
        locationsRaw,
        iocsRaw,

        // GFX
        industriesTop5Raw,
        industriesTimelineRaw,
      ] = rawAggs.aggs;

      const industriesTop5Set = new Set([
        ...industriesTop5,
        ...industriesTop5Raw,
      ].filter((d) => d.key.some(isNotNull)).map((i) => i.key[1]));

      const locationsMap = unifiedApiAggToDonutOption(combineAggEntries(locations, locationsRaw));
      const industriesTimelineOption = unifiedApiAggToTimelineOption(
        fillMissingDates(combineAggEntries(industriesTimeline, industriesTimelineRaw)
          .filter((item) => industriesTop5Set.has(item.key[2]))),
      );
      const intentDonut = unifiedApiAggToDonutOption(safeMergeArrays(threatActorIntent, threatActorIntentRaw));
      const motivationDonut = unifiedApiAggToDonutOption(threatActorMotivationRaw);

      const contextData = {
        threat_actor_motivation: normalizeAggEntry(threatActorMotivationRaw || []),
        threat_actor_intent: normalizeAggEntry(combineAggEntries(threatActorIntent, threatActorIntentRaw)),
        threat_actor_capability: normalizeAggEntry(combineAggEntries(threatActorCapability, threatActorCapabilityRaw)),
        attack_vector: normalizeAggEntry(combineAggEntries(attackVector, attackVectorRaw)),
        providers: normalizeAggEntry(combineAggEntries(providers, providersRaw)),
        personas: normalizeAggEntry(combineAggEntries(personas, personasRaw)),
        vulnerabilities: normalizeAggEntry(combineAggEntries(vulnerabilities, vulnerabilitiesRaw)),
        malware_kits: normalizeAggEntry(combineAggEntries(malwareKits, malwareKitsRaw)),
        relevant_sources: normalizeAggEntry(combineAggEntries(sources, sourcesRaw)),
        companies: normalizeAggEntry(combineAggEntries(companies, companiesRaw)),
        relevant_industries: normalizeAggEntry(combineAggEntries(industries, industriesRaw)),
        relevant_technologies: normalizeAggEntry(combineAggEntries(technologies, technologiesRaw)),
        relevant_locations: normalizeAggEntry(combineAggEntries(locations, locationsRaw)),
        iocs: safeMergeArrays(iocs, iocsRaw).map((ioc) => ioc.key[0]),

        // GFX
        industriesTimelineOption,
        intentDonut,
        motivationDonut,
        locationsMap,
      };
      const entityRawIntel = Array.isArray(entity.data?.data?.raw_intel) ? entity.data?.data?.raw_intel : [];

      return {
        ...(entity.data?.data as NonUndefined<ThreatActorsResourceFetchResponse>),
        ...contextData,
        attcks_overview: createOverviewAttckObject(entityRawIntel.flatMap((raw) => (
          Array.isArray(raw.attcks) ? raw.attcks : []
        ))),
      };
    },
    enabled,
  };
}

function useThreatActors() {
  const service = useCreateUnifiedApiService('/catalog/threat_actors/', openapiClient);

  return {
    list: service.list,
  };
}

export function useThreatActorEntity(id: number, enabled?: boolean) {
  return useQuery(threatActorsEntityQuery(id, enabled));
}

export function useThreatActorsTable({
  filter,
  ...params
}: Partial<UnifiedApiParams> = {}) {
  const auth = useAuth();

  return useThreatActors().list({
    placeholderData: keepPreviousData,
    queryKey: threatActorKeys.table,
    select: (raw) => {
      const getFromRawIntel = (record: Schema<'ThreatActorResponse'>) => (
        Array.isArray(record.raw_intel) ? record.raw_intel.reduce((accu, curr) => {
          const {
            locations,
            industries,
            technologies,
            attack_vector: attackVector,
            threat_actor_capability: threatActorCapability,
            threat_actor_intent: threatActorIntent,
            threat_actor_motivation: threatActorMotivation,
          } = curr;

          accu.relevantLocations = {
            ...accu.relevantLocations,
            ...Object.fromEntries((locations as Array<Locations>).map((l) => ([l.id, l.name]))),
          };

          accu.relevantIndustries = {
            ...accu.relevantIndustries,
            ...industries as Record<string, string>,
          };

          accu.relevantTechnologies = {
            ...accu.relevantTechnologies,
            ...technologies as Record<string, string>,
          };
          accu.regions = {
            ...accu.regions,
            ...getRegions(locations as Array<Locations>),
          };

          accu.attack_vector = mergeUniqueArray(accu.attack_vector, attackVector);
          accu.threat_actor_capability = mergeUniqueArray(accu.threat_actor_capability, threatActorCapability);
          accu.threat_actor_intent = mergeUniqueArray(accu.threat_actor_intent, threatActorIntent);
          accu.threat_actor_motivation = mergeUniqueArray(accu.threat_actor_motivation, threatActorMotivation);

          return accu;
        }, {
          regions: {},
          relevantLocations: {},
          relevantIndustries: {},
          relevantTechnologies: {},
          attack_vector: [],
          threat_actor_capability: [],
          threat_actor_intent: [],
          threat_actor_motivation: [],
        } as Record<
        'threat_actor_capability' | 'threat_actor_intent' | 'threat_actor_motivation' | 'attack_vector',
        Array<string>
        > & Record<
        'relevantLocations' | 'relevantIndustries' | 'relevantTechnologies' | 'regions',
        Record<string, string>
        >) : {});

      const data = {
        ...raw.data,
        records: raw.data?.records.map((record) => ({
          ...record,
          ...getFromRawIntel(record),
        })),
      };

      return data;
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        ...params,
        filter: [
          'raw_intel:*',
          ...(filter ?? []),
        ],
        include: [
          'raw_intel.locations',
        ],
      },
    },
    headers: { source: 'table' },
  });
}

export function useThreatActorsGFX(
  filter: UnifiedApiParams['filter'] = [],
) {
  const auth = useAuth();

  const finishedIntel = useFinishedIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => threatActorKeys.gfx(INTEL_ENDPOINTS.finished, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        locations,
        threatActors,
        threatActorsTimeline,
        threatActorIntent,
        threatActorCapability,
      ] = data.aggs;

      return {
        locations,
        threatActors,
        threatActorsTimeline,
        threatActorIntent,
        threatActorCapability,
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        filter: [
          'threat_actors:{name:*}',
          'populate_catalog:true',
          ...(filter.filter((val) => !val.includes('motivation'))),
        ],
        agg: [
          'locations',
          'threat_actors limit:5',
          'month(published_at) threat_actors order:asc',
          'threat_actor_intent',
          'threat_actor_capability',
        ],
      },
    },
  });

  const rawIntel = useRawIntel().list({
    placeholderData: keepPreviousData,
    queryKey: (params) => threatActorKeys.gfx(INTEL_ENDPOINTS.raw, params),
    select: (raw) => {
      const data = raw.data as unknown as UnifiedApiAggResponse;

      const [
        locations,
        threatActors,
        threatActorsTimeline,
        threatActorMotivation,
        threatActorIntent,
        threatActorCapability,
      ] = data.aggs;

      return {
        locations,
        threatActors,
        threatActorsTimeline,
        threatActorMotivation,
        threatActorIntent,
        threatActorCapability,
      };
    },
    enabled: auth.userInfoQuery.isSuccess,
  }, {
    params: {
      query: {
        length: 0,
        filter: [
          'threat_actors:{name:*}',
          'status:published',
          ...filter,
        ],
        agg: [
          'locations',
          'threat_actors limit:5',
          'month(published_at) threat_actors order:asc',
          'threat_actor_motivation',
          'threat_actor_intent',
          'threat_actor_capability',
        ],
      },
    },
  });

  const data = useMemo(() => {
    if (!finishedIntel.data && !rawIntel.data) return undefined;

    const {
      locations,
      threatActors,
      threatActorsTimeline,
      threatActorIntent,
      threatActorCapability,
    } = finishedIntel.data || {};

    const {
      locations: locationsRaw,
      threatActors: threatActorsRaw,
      threatActorsTimeline: threatActorsTimelineRaw,
      threatActorIntent: threatActorIntentRaw,
      threatActorCapability: threatActorCapabilityRaw,
      threatActorMotivation,
    } = rawIntel.data || {};

    const top5ThreatActors = unifiedApiToGradientOption(combineAggEntries(threatActorsRaw, threatActors).slice(0, 5));
    const top5Set = new Set(top5ThreatActors.dimensions);
    const threatActorsTimelineOption = unifiedApiAggToTimelineOption(
      fillMissingDates(
        combineAggEntries(threatActorsTimelineRaw, threatActorsTimeline).filter((item) => top5Set.has(item.key[2])),
      ),
    );

    return {
      top5ThreatActors,
      threatActorsTimelineOption,
      locationsMap: unifiedApiAggToDonutOption(combineAggEntries(locationsRaw, locations)),
      motivationDonut: unifiedApiAggToDonutOption(threatActorMotivation),
      intentDonut: unifiedApiAggToDonutOption(combineAggEntries(threatActorIntentRaw, threatActorIntent)),
      capabilityDonut: unifiedApiAggToDonutOption(combineAggEntries(threatActorCapabilityRaw, threatActorCapability)),
    };
  }, [finishedIntel.data, rawIntel.data]);

  return {
    isPending: finishedIntel.isPending || rawIntel.isPending,
    isError: finishedIntel.isError && rawIntel.isError,
    isFetching: finishedIntel.isFetching || rawIntel.isFetching,
    error: finishedIntel.error || rawIntel.error,
    data,
  };
}
